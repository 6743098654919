import { NodeEntity } from 'Models/Entities';
import { nodeColors } from './NodeStyles';
import * as PIXI from 'pixi.js';
import MapObject from '../MapObject';
import MapRenderer from '../../MapRenderer';
import { nodetaskOptions } from '../../../../../Models/Enums';
import MapStore from '../../MapStore';
import { getLeafletLatLng, PixiCoordinates, realWorldCoordinates } from '../../Helpers/Coordinates';
import { DashLine } from '../../Helpers/DashLine';
import {offsetAlongHeadingPixi} from "../../Helpers/MapUtils";
import {HoverState} from "../../MapStateHandlerHelpers/PathToolHelper";

/**
 * Each unique visual representation of nodes
 */
export enum nodeDisplay {
	Start,
	StartWithTask,
	StartWithHaulingTask,
	StartWithError,
	StartWithWarning,
	End,
	EndWithTask,
	EndWithHaulingTask,
	EndWithError,
	EndWithWarning,
	TaskWithError,
	TaskWithWarning,
	HaulingTask,
	NormalTask,
	MidWaypoint,
	MidWaypointWithTask,
	Warning,
	Error,
}

const CONNECTIVITY_LINE_WIDTH = 2;
const CONNECTIVITY_LINE_COLOR = 0xFFFFFF;
const GUIDE_CONNECTIVITY_LINE_WIDTH = 1.5; // same as the width as UNSELECTED LINK
const GUIDE_CONNECTIVITY_LINE_COLOR = 0xFFFFFF;
const GUIDE_LINE_WIDTH = 1.5; // same as the width as UNSELECTED LINK
const GUIDE_LINE_COLOR = 0xFFFFFF;
const DASH_LENGTH = 5;
const DASH_GAP_LENGTH = 3;

interface INodeRadius {
	/** Radius of node including stroke */
	outerRadius: number;
	/** Radius of node exluding stroke */
	innerRadius: number;
}

interface INodeOptions {
	isReverseMidWaypoint?: boolean;
	isSelected?: boolean;
	isEditMode?: boolean;
}

interface IStraghtLineInstance {
	objId: string;
	lineId: number | undefined;
}

/**
 * Renders everything associated with a node in Pixi, including
 * - node style
 * - heading graphic
 * - manual connectivity graphic
 */
export default class NodeGraphic extends MapObject<NodeEntity> {
	private _styleType: nodeDisplay;
	private _coordinates: PixiCoordinates;
	/**
	 * The ratio of the outerRadius for standard node to start/end node is 1:2 and
	 * the ratio of the innerRadius for standard node to start/end node is 2:3
	 * The values below are calculated according to this equation.
	 * ex: If outerRadius for the start and end node is 10 and innerRadius is 9 then
	 * 10 - 9 = 1 and 2/3 of 1 is ~0.66
	 * so, to satisfy the equation, outerRadius for the standard node will be
	 * 5 (half of the start/end node) and innerRadius will be 4.34 (5 - 0.66).
	 */
	static readonly startEndNodeRadius: INodeRadius = { outerRadius: 10, innerRadius: 9 };
	static readonly standardNodeRadius: INodeRadius = { outerRadius: 5, innerRadius: 4.34 };
	static readonly dynamicConnectionRadius: INodeRadius = { outerRadius: 19.5, innerRadius: 18 };
	static readonly headingLineLength: number = 50;

	static readonly firstNodeIndex: number = 0;

	static readonly overlappingNodeIndex: number = 1;

	// Graphics added and removed as needed
	private guidelineGraphic: PIXI.Graphics | undefined;
	private _headingGraphic: PIXI.Graphics | undefined;
	private connectivityGraphic: PIXI.Graphics | undefined;

	private _isSelected = false;
	// NodeEntity ismidwaypoint will be FALSE for isReverseMidWaypoints
	private isReverseMidWaypoint = false;

	private _isEditMode = false;

	// allows only once instance of line and ensures correct removal
	public static straightLineInstance: IStraghtLineInstance = {
		objId: '',
		lineId: undefined
	};

	public headingAnimationFrameId: number | undefined;

	private isLineConnecivity: boolean;

	public isConnectivityEndpoint: boolean | undefined;

	public isEntryNode: boolean | undefined;
	public isExitNode: boolean | undefined;
	public isDisplayDynamicConnections: boolean | undefined;

	public currentScale: number = 1;

	private culled: boolean = true;

	/**
	 * Creates an instance of node graphic.
	 * @param renderer
	 * @param entity
	 * @param [options]
	 * @param lookup
	 */
	constructor(renderer: MapRenderer, entity: NodeEntity, options?: INodeOptions, lookup?: MapStore) {
		super(renderer, 'node', entity);
		lookup?.addEntityToMapObject(entity.getModelId(), this);

		if (options) {
			this.isSelected = options.isSelected ?? false;
			this.isReverseMidWaypoint = options.isReverseMidWaypoint ?? false;
			this.isEditMode = options.isEditMode ?? false;
		}

		if (!this.isReverseMidWaypoint) {
			this.isReverseMidWaypoint = entity.task === 'REVERSEPOINT' && entity.isMidWaypoint;
		}

		this.styleType = this.getNodeStyle();
		this.createGraphic(); // node
		// Other graphics added/removed dynamically as needed
	}

	/**
	 * Return true if the current node should always be rendered to the screen (Even if it is outside the map view)
	 * eg. has a line connectivity, or a line when drawing a clothoid path
	 */
	public shouldAlwaysRender(): boolean {
		return this.guidelineGraphic !== undefined;
	}

	public selectAndReRender() {
		this.updateIsSelectAndReRender(true);
	}

	public deselectAndReRender() {
		this.updateIsSelectAndReRender(false);
	}

	private updateIsSelectAndReRender(isSelected: boolean) {
		this.isSelected = isSelected;
		this.markToReRender();
		this.renderer.rerender();
	}

	public get headingGraphic() {
		return this._headingGraphic;
	}

	public set isSelected(_isSelected: boolean) {
		this._isSelected = _isSelected;
	}

	public get isSelected() {
		return this._isSelected;
	}

	public set isEditMode(_isEditMode: boolean) {
		this._isEditMode = _isEditMode;
	}

	public get isEditMode() {
		return this._isEditMode;
	}

	public panToObject() {
		this.renderer.getMap().panTo(this.renderer.getLeafletCoords(this.entity));
	}

	public dispose() {
		// Make sure to dispose of the tooltip
		this.removeTooltip();
		if (!!this.headingAnimationFrameId) {
			this.clearDynamicHeading();
		}
		if (NodeGraphic.straightLineInstance.objId === this.getId()) {
			this.clearSingleLine();
		}
	}

	public render() {
		const nodeEntity = this.entity;
		this.coordinates = this.renderer.project(nodeEntity);
		this.styleType = this.getNodeStyle();

		this.drawNode();

		if (this.isSelected && this.isWaypoint()) {
			this.renderHeading();
		}

		if (this.isConnectivityEndpoint === true || this.isDisplayDynamicConnections === true) {
			// special task will not be shown on the map while Connectivity End Point is shown.
			this.removeTooltip();
		} else {
			this.renderLabel();
		}
	}

	public isEntryOrExit() {
		return this.isEntryNode || this.isExitNode;
	}

	public isStartOrEnd() {
		return (this.styleType === nodeDisplay.Start
			|| this.styleType === nodeDisplay.End
			|| this.isStartOrEndWithTask());
	}

	public isWaypoint() {
		return this.isStartOrEnd() || this.styleType === nodeDisplay.MidWaypoint || this.styleType === nodeDisplay.MidWaypointWithTask;
	}

	// note: this is used to determine styleType
	public isMidWaypoint() {
		return this.getEntity().isMidWaypoint === true;
	}

	public isStartWithHaulingTaskOrEndWithTask() {
		return (this.isStartWithHaulingTask()
			|| this.styleType === nodeDisplay.End
			|| this.styleType === nodeDisplay.EndWithTask
			|| this.styleType === nodeDisplay.EndWithHaulingTask);
	}

	public isStartWithHaulingTask() {
		return this.styleType === nodeDisplay.StartWithHaulingTask;
	}

	public isStartOrEndWithTask() {
		return (this.styleType === nodeDisplay.StartWithTask
			|| this.styleType === nodeDisplay.StartWithHaulingTask
			|| this.styleType === nodeDisplay.EndWithTask
			|| this.styleType === nodeDisplay.EndWithHaulingTask);
	}

	public isStart() {
		return (this.styleType === nodeDisplay.Start
			|| this.styleType === nodeDisplay.StartWithTask
			|| this.styleType === nodeDisplay.StartWithHaulingTask);
	}

	public isEnd() {
		return (this.styleType === nodeDisplay.End
			|| this.styleType === nodeDisplay.EndWithTask
			|| this.styleType === nodeDisplay.EndWithHaulingTask);
	}

	public setCulled(culled: boolean) {
		if (!this.culled && !this.isRenderable()) {
			return;
		}

		this.culled = true;
		super.setRenderable(!culled);
	}

	public setRenderable(renderable: boolean) {
		this.culled = false;
		super.setRenderable(renderable);
	}

	/**
	 * Used when creating a path to show a straight line between the first waypoint
	 * and current mouse position
	 */
	public startGuideLine() {
		this.startSingleLine(false);
	}

	public clearGuideLine() {
		this.clearSingleLine();
	}

	/**
	 * Used to show straight line following mouse cursor when creating manually connectivity.
	 */
	public startConnectivity() {
		this.startSingleLine(true);
	}

	public clearConnectivity() {
		this.clearSingleLine();
	}

	/**
	 * Renders the line graphic
	 * It must be called after startLine and has its
	 * position updated according to the mouse position
	 * @param end
	 */
	private renderLine(end: PixiCoordinates) {
		if (!this.guidelineGraphic) {
			this.guidelineGraphic = this.addGraphic();
		} else {
			this.guidelineGraphic.clear();
		}
		const graphic = this.guidelineGraphic;
		graphic.zIndex = 90;
		const from = this.coordinates;
		let to = this.coordinates;
		if (end) {
			to = end;
		}
		if (this.isLineConnecivity) {
			const dash = new DashLine(graphic, {
				width: GUIDE_CONNECTIVITY_LINE_WIDTH,
				color: GUIDE_CONNECTIVITY_LINE_COLOR,
				dash: [DASH_LENGTH, DASH_GAP_LENGTH],
			});
			dash.moveTo(from.x, from.y)
				.lineTo(to.x, to.y);
		} else {
			graphic.lineStyle(GUIDE_LINE_WIDTH, GUIDE_LINE_COLOR)
				.moveTo(from.x, from.y)
				.lineTo(to.x, to.y);
		}
	}

	/**
	 * Initialises this positioning of the line graphic
	 * and calls its rendering method
	 */
	private startSingleLine(isLineConnecivity: boolean) {
		const { lineId } = NodeGraphic.straightLineInstance;
		if (lineId === undefined) {
			this.isLineConnecivity = isLineConnecivity;
			const renderLine = () => {
				this.isAnimating('line');
				this.renderLine(this.renderer.project(this.renderer.mousePosition));
				this.renderer.rerender();
				NodeGraphic.straightLineInstance.lineId = requestAnimationFrame(renderLine);
			};
			NodeGraphic.straightLineInstance.objId = this.getId();
			NodeGraphic.straightLineInstance.lineId = requestAnimationFrame(renderLine);
		} else {
			this.clearSingleLine();
			this.startSingleLine(isLineConnecivity);
		}
	}

	/**
	 * Removes and clears the line
	 */
	private clearSingleLine() {
		this.removeGraphic(this.guidelineGraphic);
		this.guidelineGraphic = undefined;
		const { lineId } = NodeGraphic.straightLineInstance;
		if (lineId !== undefined) {
			cancelAnimationFrame(lineId);
			NodeGraphic.straightLineInstance.lineId = undefined;
			this.renderer.rerender();
		}
	}


	/**
	 * Call render connectivity based on the coords passed
	 * @param start
	 * @param end
	 * @param isVisible
	 */
	public showConnectivity(start: PixiCoordinates, end: PixiCoordinates, isVisible: boolean) {
		this.renderConnectivityLine(start, end, isVisible);
	}

	/**
	 * Removes the connectivity graphic for the node
	 */
	public hideConnectivity() {
		this.removeGraphic(this.connectivityGraphic);
		this.connectivityGraphic = undefined;
	}

	/**
	 * Displays the dashed line to represent the connectivity
	 * @param start
	 * @param end
	 * @param isVisible
	 * @private
	 */
	private renderConnectivityLine(start: PixiCoordinates, end: PixiCoordinates, isVisible: boolean) {
		if (!this.connectivityGraphic) {
			this.connectivityGraphic = this.addGraphic();
		} else {
			this.connectivityGraphic.clear();
		}
		const graphic = this.connectivityGraphic;
		graphic.zIndex = this.zIndexTop;
		graphic.visible = isVisible;
		const dash = new DashLine(graphic, {
			width: CONNECTIVITY_LINE_WIDTH,
			color: CONNECTIVITY_LINE_COLOR,
			dash: [DASH_LENGTH, DASH_GAP_LENGTH],
		});
		dash.moveTo(start.x, start.y)
			.lineTo(end.x, end.y);
	}

	/**
	 * Initialises this positioning of the heading graphic
	 * and calls its rendering method
	 */
	public startDynamicHeading() {
		if (this.headingAnimationFrameId === undefined) {
			const renderHeading = () => {
				this.isAnimating('heading');
				this.renderHeading();
				this.renderer.rerender();
				this.headingAnimationFrameId = requestAnimationFrame(renderHeading);
			};
			this.headingAnimationFrameId = requestAnimationFrame(renderHeading);
		}
	}

	public clearDynamicHeading() {
		if (!!this.headingAnimationFrameId) {
			cancelAnimationFrame(this.headingAnimationFrameId);
			this.removeGraphic(this._headingGraphic);
			this._headingGraphic = undefined;
			this.headingAnimationFrameId = undefined;
		}
	}

	// At graphic not present at time of initialisation
	private addGraphic() {
		const graphic = new PIXI.Graphics();
		graphic.name = this.getId();
		this.renderer.getContainer(this.getType()).addChild(graphic);
		this.containers.push(graphic);
		return graphic;
	}

	// Remove graphic before disposal
	private removeGraphic(graphic?: PIXI.Graphics) {
		if (!!graphic) {
			this.containers = this.containers.filter(g => g !== graphic)
			this.renderer.getContainer(this.getType()).removeChild(graphic);
		}
	}

	/**
	 * Render and style the interactive heading graphic
	 */
	private renderHeading() {
		if (!this._headingGraphic) {
			this._headingGraphic = this.addGraphic();
		} else {
			this._headingGraphic.clear();
		}
		const graphic = this._headingGraphic;

		// Heading updated in entity
		const angleDegrees = this.getEntity().getHeading();
		const rectWidth = 4;
		const rectHeight = 4;
		const segmentLength = NodeGraphic.headingLineLength;
		const xC = this.coordinates.x;
		const yC = this.coordinates.y;
		const x1 = xC;
		const x2 = xC;
		const y1 = yC - segmentLength;
		const y2 = yC + segmentLength;
		graphic.lineStyle(1, 0x000000)
			.moveTo(x1, y1)
			.lineTo(x2, y2);

		// heading triangle
		graphic.beginFill(0x000000);
		graphic.drawPolygon([
			new PIXI.Point(x1 - rectWidth / 2, y1),
			new PIXI.Point(x1 + rectWidth / 2, y1),
			new PIXI.Point(x1, y1 - rectHeight),
		]);
		graphic.endFill();

		graphic.beginFill(0x5FBCFF);
		graphic.drawRect(x2 - rectWidth / 2, y2 - rectHeight / 2, rectWidth, rectHeight);
		graphic.endFill();

		graphic.pivot.set(xC, yC);
		graphic.x = xC;
		graphic.y = yC;

		// graphic.angle = angleDegrees;
		graphic.angle = !angleDegrees ? 0 : angleDegrees;
		graphic.zIndex = this.zIndexTop + 1;
	}

	private renderLabel() {
		// shift label up 0.5 and left by 1
		const coords = realWorldCoordinates(this.entity.northing + 0.5, this.entity.easting - 1);
		if (this.entity.task !== 'HAULING' && this.entity.task !== 'NONE') {
			const mapCoords = this.renderer.getLeafletCoords(coords);

			this.createTooltip(nodetaskOptions[this.entity.task], getLeafletLatLng(mapCoords));
		} else if (this.entity.task === 'HAULING' && this.isEditMode === true && this.entity.getErrorCount() > 0) {
			const mapCoords = this.renderer.getLeafletCoords(coords);
			let errorText = '';
			errorText = this.entity.getErrors().map(e => e.errorMessage).join(', ');
			errorText = errorText.replace('NodeTurnbackPathStraightDistanceError', 'TSD').replace('NodeCurvatureError', 'CT');
			this.createTooltip(errorText, getLeafletLatLng(mapCoords));
		} else {
			this.removeTooltip();
		}
	}

	/**
	 * Assumptions: The following refs must be set correctly
	 * node.sublink, node.sublink.nextSublink, node.nextNode
	 * @returns
	 */
	private getNodeStyle(): nodeDisplay {
		const node = this.entity;
		let styleType: nodeDisplay = nodeDisplay.NormalTask;

		const nextNode = node.nextNode ?? node.getNextNode();
		const sublink = node.sublink ?? node.getSublink();
		const nextSublink = sublink?.nextSublink ?? sublink?.getNextSublink();

		if (node.isStartNodeOfLink()) {
			if (node.task === 'NONE') {
				styleType = nodeDisplay.Start;
			} else if (node.task === 'HAULING') {
				styleType = nodeDisplay.StartWithHaulingTask;
			} else {
				styleType = nodeDisplay.StartWithTask;
			}
		} else if (node.isEndNodeOfLink()) {
			if (node.task === 'NONE') {
				styleType = nodeDisplay.End;
			} else if (node.task === 'HAULING') {
				styleType = nodeDisplay.EndWithHaulingTask;
			} else {
				styleType = nodeDisplay.EndWithTask;
			}
		} else if (node.task === 'HAULING') {
			styleType = (this.isMidWaypoint() && this.isSelected) ? nodeDisplay.MidWaypoint : nodeDisplay.HaulingTask;
		} else {
			styleType = this.isSelected ? nodeDisplay.MidWaypointWithTask : nodeDisplay.NormalTask;
		}

		return styleType;
	}

	/**
	 * Draws node with styling according to type of node
	 * If it's a start or end node, it consists of two graphics
	 * with one large and one small. Other nodes consist of only
	 * the small graphic
	 */
	public drawNode() {
		const graphic = this.getGraphic();
		graphic.clear();

		const radius = this.isStartOrEnd() || this.isMidWaypoint()
			? NodeGraphic.startEndNodeRadius.outerRadius : NodeGraphic.standardNodeRadius.outerRadius;
		graphic.hitArea = new PIXI.Circle(0, 0, radius);

		graphic.zIndex = this.isHighlighted ? this.zIndexTop : this.zIndexBase;
		const isOverlapping = true;
		let displayStyle = this.getNodeStyle();
		if (this.isConnectivityEndpoint === true || this.isDisplayDynamicConnections === true) {
			// Don't display special task when showing as connectivity endpoint or Dynamic Connection
			if (this.isStart()) {
				displayStyle = nodeDisplay.Start;
			} else if (this.isEnd()) {
				displayStyle = nodeDisplay.End;
			}
		}

		// Use errorFill as a param here for potential future customisation of error fill
		const errorFill = this.isError ? nodeColors.ErrorNode : (this.isWarning ? nodeColors.WarningNode : undefined);
		switch (displayStyle) {
			case nodeDisplay.Start:
				if (this.isDisplayDynamicConnections === true) {
					this._drawDynamicConnection();
				}
				this.drawStartEndNode(nodeColors.StartNode, errorFill);
				break;
			case nodeDisplay.StartWithTask:
				this.drawStartEndNode(nodeColors.StartNode);
				this.drawStandardNode(nodeColors.TaskNode, isOverlapping, errorFill);
				break;
			case nodeDisplay.StartWithHaulingTask:
				this.drawStartEndNode(nodeColors.StartNode);
				if (this.isError || this.isWarning) {
					this.drawStandardNode(nodeColors.ErrorNode, isOverlapping, errorFill);
				}
				break;
			case nodeDisplay.StartWithError:
				// This style is not used.
				this.drawStartEndNode(nodeColors.StartNode);
				this.drawStandardNode(nodeColors.ErrorNode, isOverlapping, errorFill);
				break;
			case nodeDisplay.StartWithWarning:
				// This style is not used.
				this.drawStartEndNode(nodeColors.StartNode);
				this.drawStandardNode(nodeColors.WarningNode, isOverlapping, errorFill);
				break;
			case nodeDisplay.End:
				if (this.isDisplayDynamicConnections === true) {
					this._drawDynamicConnection();
				}
				this.drawStartEndNode(nodeColors.EndNode, errorFill);
				break;
			case nodeDisplay.EndWithError:
				// This style is not used.
				this.drawStartEndNode(nodeColors.EndNode);
				this.drawStandardNode(nodeColors.ErrorNode, isOverlapping, errorFill);
				break;
			case nodeDisplay.EndWithWarning:
				// This style is not used.
				this.drawStartEndNode(nodeColors.EndNode);
				this.drawStandardNode(nodeColors.WarningNode, isOverlapping, errorFill);
				break;
			case nodeDisplay.EndWithTask:
				this.drawStartEndNode(nodeColors.EndNode);
				this.drawStandardNode(nodeColors.TaskNode, isOverlapping, errorFill);
				break;
			case nodeDisplay.EndWithHaulingTask:
				this.drawStartEndNode(nodeColors.EndNode);
				if (this.isError || this.isWarning) {
					this.drawStandardNode(nodeColors.ErrorNode, isOverlapping, errorFill);
				}
				break;
			case nodeDisplay.NormalTask:
				this.drawStandardNode(nodeColors.TaskNode, false, errorFill);
				break;
			case nodeDisplay.HaulingTask:
				this.drawStandardNode(nodeColors.HaulingTaskNode, false, errorFill);
				break;
			case nodeDisplay.TaskWithError:
				// This style is not used.
				this.drawStandardNode(nodeColors.ErrorNode);
				break;
			case nodeDisplay.TaskWithWarning:
				// This style is not used.
				this.drawStandardNode(nodeColors.WarningNode);
				break;
			case nodeDisplay.MidWaypoint:
				this.drawStartEndNode(nodeColors.MidWaypoint);
				if (this.isError || this.isWarning) {
					this.drawStandardNode(nodeColors.ErrorNode, isOverlapping, errorFill);
				}
				break;
			case nodeDisplay.MidWaypointWithTask:
				this.drawStartEndNode(nodeColors.MidWaypoint);
				this.drawStandardNode(nodeColors.TaskNode, isOverlapping, errorFill);
				break;
			default:
				console.log('Not found');
				break;
		}
	}

	get coordinates() {
		return this._coordinates;
	}

	set coordinates(newCoordinates: PixiCoordinates) {
		this._coordinates = newCoordinates;
	}

	get styleType() {
		return this._styleType;
	}

	set styleType(newStyleType: nodeDisplay) {
		this._styleType = newStyleType;
	}

	public _updateScale(isReset: boolean) {
		const graphic = this.getGraphic();
		const newScale = isReset ? 1 : 1 / this.renderer.pixiScale;
		graphic.scale.set(newScale);
		this.renderer.pixiCurrentAppliedZoom = this.renderer.pixiZoom;
	}

	private drawStartEndNode(fillColor: number, errorFillColor?: number) {
		const _fillColor = (errorFillColor !== undefined) && (this.isError || this.isWarning) ? errorFillColor : fillColor
		const nodeRadius = NodeGraphic.startEndNodeRadius;
		const flipColours = this.isConnectivityEndpoint === true;
		const fill = flipColours ? nodeColors.NodeStroke : _fillColor;
		const stroke = flipColours ? _fillColor : nodeColors.NodeStroke;
		const radiusMultiplier = 1;
		this._drawCircleGraphic(stroke, nodeRadius, fill, nodeRadius.innerRadius * radiusMultiplier);
	}

	private drawStandardNode(fillColor: number, isOverlapping: boolean = false, errorFillColor?: number) {
		const nodeRadius = NodeGraphic.standardNodeRadius;
		const fill = (errorFillColor !== undefined) && (this.isError || this.isWarning) ? errorFillColor : fillColor;
		let radiusMultiplier = 1;
		if (this.isEditMode && this.isError && !isOverlapping) {
			radiusMultiplier *= 1.5;
		}
		this._drawCircleGraphic(nodeColors.NodeStroke, nodeRadius, fill, nodeRadius.innerRadius * radiusMultiplier, isOverlapping);
	}

	/**
	 * Drawing method for nodes
	 * @param strokeColor
	 * @param nodeRadius
	 * @param fillColor
	 * @param circleRadius
	 * @param isOverlapping
	 */
	// eslint-disable-next-line max-len
	private _drawCircleGraphic(strokeColor: number, nodeRadius: INodeRadius, fillColor: number, circleRadius: number, isOverlapping: boolean = false) {
		let widthMultiplier = !isOverlapping && this.isHighlighted ? 2 : 1;
		const graphic = this.getGraphic();
		const strokeWidth = (nodeRadius.outerRadius - nodeRadius.innerRadius) * widthMultiplier;
		graphic.lineStyle(strokeWidth, strokeColor);
		graphic.beginFill(fillColor);
		graphic.position.set(this.coordinates.x, this.coordinates.y);
		graphic.drawCircle(0, 0, circleRadius);
		graphic.endFill();
	}
	// eslint-disable-next-line max-len
	private _drawDynamicConnection() {
		let radiusMultiplier = 1;
		let widthMultiplier = 1;
		let circleFillColor = nodeColors.DynamicConnectionFill;
		const nodeRadius = NodeGraphic.dynamicConnectionRadius;
		const strokeColor = nodeColors.DynamicConnectionStroke;
		const graphic = this.getGraphic();
		const strokeWidth = (nodeRadius.outerRadius - nodeRadius.innerRadius) * widthMultiplier;
		graphic.lineStyle(strokeWidth, strokeColor);
		graphic.beginFill(circleFillColor, 0.75);
		graphic.drawCircle(0, 0, nodeRadius.innerRadius * radiusMultiplier);
		graphic.endFill();
	}
}
