import * as React from 'react';
import { observer } from 'mobx-react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { TextField } from 'Views/Components/TextBox/TextBox';
import hitachiTrucks from '../../images/HITACHI-TRUCKS-EH5000-LINE-UP-001-2000x2000-1.jpg';
import hitachiLogo from 'Assets/images/HCM logo transparent-360x180.png';
import { Password } from 'Views/Components/Password/Password';
import { Button, Sizes } from 'Views/Components/Button/Button';
import { action, observable } from 'mobx';
import * as queryString from 'querystring';
import { activateAccount } from 'Services/Api/AccountService';
import { store } from 'Models/Store';
import alert from '../../Util/ToastifyUtils';
import { PASSWORD_REGEX } from 'Constants';

interface IActivationState {
    token: string;
    username: string;
    firstName: string;
	lastName: string;
	password: string;
	confirmPassword: string,
    errorFlag: boolean,
	errors: {
        firstName?: string,
        lastName?: string,
		password?: string,
		confirmPassword?: string,
		[attr: string]: string | undefined,
	};
}

const defaultActivationState: IActivationState = {
    token: '',
    username: '',
    firstName: '',
	lastName: '',
    password: '',
    confirmPassword: '',
    errorFlag: false,
    errors: {},
};

@observer
export default class ActivationPage extends React.Component<RouteComponentProps> {

    @observable
	private activationState: IActivationState = defaultActivationState;

	constructor(props: RouteComponentProps) {
		super(props);
		const { location } = this.props;
		const queryParams = location.search.substring(1);
		const { token, username, givenname, familyname } = queryString.parse(queryParams);
		this.activationState.token = token as string;
		this.activationState.username = username as string;
		this.activationState.firstName = givenname as string;
		this.activationState.lastName = familyname as string;
	}

	public render() {
		let pageContent = (
			<div className="body-content">
				<div className="row">
					<div className="column">
						<form className="activation" onSubmit={e => this.onActivateButtonClicked(e)} >
							<h1 className="activation-headers">Welcome to the</h1>
							<h1 className="activation-mat-text-bottom">Map Authoring Tool</h1>
							<h3 className="subtitle">Activate your account.</h3>
                            <TextField
                            	id="first_name"
                                className='first-name'
                                model={this.activationState}
                                modelProperty={"firstName"}
                                label="First Name"
                                labelVisible
                                isRequired
                                placeholder="First Name"
                                inputProps={{ type: "text" }}
                                errors={this.activationState.errors.firstName} />
                            <TextField
                                id="last_name"
                                className='last-name'
                                model={this.activationState}
                                modelProperty={"lastName"}
                                label="Last Name"
                                labelVisible
                                isRequired
                                placeholder="Last Name"
                                inputProps={{ type: "text" }}
                                errors={this.activationState.errors.lastName} />
                            <Password
                                id="new_password"
                                className="activation-new-password"
                                model={this.activationState}
                                modelProperty="password"
                                label="Password"
                                isRequired
                                errors={this.activationState.errors.password} />
                            <div className='row'>
                                <ul className={this.activationState.errorFlag ? "activation-ul-left-error" : "activation-ul-left"}>
                                    <li>
                                        12 characters minimum
                                    </li>
                                    <li>
                                        One lower case
                                    </li>
                                    <li>
                                        One uppercase character
                                    </li>
                                </ul>
                                <ul className= {this.activationState.errorFlag ? "activation-ul-right-error" : "activation-ul-right"}>
                                    <li>
                                        One number
                                    </li>
                                    <li>
                                        One special character
                                    </li>
                                </ul>
                            </div>
                            <Password
                                id="confirm_password"
                                className="activation-confirm-password"
                                model={this.activationState}
                                modelProperty="confirmPassword"
                                label="Confirm Password"
                                isRequired
                                errors={this.activationState.errors.confirmPassword}
                            />  

							<Button type='submit' className="activation-submit btn--primary"
                                sizes={Sizes.Large} buttonProps={{ id: "activation_submit" }} icon={{ icon: "chevron-right", iconPos: 'icon-right' }}>Activate</Button>
						</form>
					</div>
					<div className="column">
						<img className="login-image" src={hitachiTrucks} />
					</div>
				</div>
			</div>
		);

		return pageContent;

	}
    
    @action
	private onActivateButtonClicked = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

        this.activationState.errors = {};

        var nameRegex = new RegExp("^[a-zA-Z'-]+$");
        //password field validations
        if (!(PASSWORD_REGEX.test(this.activationState.password))){
            this.activationState.errorFlag = true;
            this.activationState.errors.password =  ' ';
        }
        else {
            this.activationState.errorFlag = false;
        }
        if (!(PASSWORD_REGEX.test(this.activationState.confirmPassword))){
            this.activationState.errors.confirmPassword =  "Password is invalid and must follow the rules above.";
        }
        if (this.activationState.confirmPassword != this.activationState.password) {
            this.activationState.errors.confirmPassword = "Passwords are not matching.";
        }
        if (!nameRegex.test(this.activationState.firstName)  || this.activationState.firstName === undefined){
            this.activationState.errors.firstName = 'Names should only contain letters, hypens and/or apostrophes.';
        } 
        if (!nameRegex.test(this.activationState.lastName) || this.activationState.lastName === undefined){
            this.activationState.errors.lastName = 'Names should only contain letters, hypens and/or apostrophes.';
        } 

        if (Object.keys(this.activationState.errors).length === 0) {
			activateAccount(
				this.activationState.token,
				this.activationState.username,
				this.activationState.password,
                this.activationState.firstName,
                this.activationState.lastName,
			).then(() => {
				this.onAccountActivated();
			}).catch(errors => {
				const errorMsgs = errors.response.data.errors.map((error: any) => (<p>{error.message}</p>));
				alert(
					<div>
						<h6>Account could not be activated: </h6>
						{errorMsgs}
					</div>,
					'error',
				);
			});
		}
	};

	@action
	private onAccountActivated = () => {
		alert('Account Activated', 'success');
		store.routerHistory.push('/login');
	};

}

