import {realWorldCoordinates, RealWorldCoordinates} from "../../../Helpers/Coordinates";
import {Bay, MapController, MapRenderer, NodeGraphic} from "../../../../index";
import MapStore from "Views/MapComponents/Map/MapStore";
import {
	calcDistanceBetweenCoords,
	getOffsetAlongHeading,
	offsetAlongHeadingRealWorld
} from "../../../Helpers/MapUtils";
import PathRenderHelper from "./PathRenderHelper";
import PathManager from "./PathManager";
import {reverseDirection, Waypoint, WaypointList} from "./Waypoint";
import PathPropertiesPanelHelper from "./PathPropertiesPanelHelper";
import alertToast from "../../../../../../Util/ToastifyUtils";
import {CONNECTIVITY_PAIR_ERROR} from "../../../../../../Constants";

const BAY_RADIUS = 3;
const NODE_RADIUS = 3;

const NODE_SNAP_DISTANCE = 3;

export interface SnapOption {
	// The type of snap possible
	type: 'node' | 'bay' | 'start' | 'end';
	// The entity of the other object that is being snapped to
	entityId: string;
	// The location the current placed waypoint should be placed
	targetLocation: RealWorldCoordinates;
	// The heading the current placed waypoint should have
	targetHeading: number;
	// Whether this should trigger as a failure
	isFailure?: boolean;
}

export default class PathSnapHelper {

	private readonly mapRenderer: MapRenderer;
	private readonly mapStore: MapStore;
	private readonly renderHelper: PathRenderHelper;
	private readonly waypoints: WaypointList;
	private readonly propertiesHelper: PathPropertiesPanelHelper;
	private readonly pathManager: PathManager;

	private readonly nodeMinDistance: number;
	private readonly truckOffset: number;

	constructor(controller: MapController, pathManager: PathManager) {
		this.renderHelper = pathManager.renderHelper;
		this.waypoints = pathManager.waypoints;
		this.propertiesHelper = pathManager.propertiesHelper;
		this.mapRenderer = controller.getMapRenderer();
		this.mapStore = controller.getMapLookup();
		this.pathManager = pathManager;

		this.nodeMinDistance = this.mapStore.getMapParameters().nodeDistMin + 0.1;
		this.truckOffset = this.mapStore.getMapParameters().truckOffset;
	}

	public getIgnoredNodeIds(): string[] {
		const pathObjects = this.renderHelper.getPathObject();
		const ignoredNodeIds: string[] = [];

		if (pathObjects === undefined) {
			this.renderHelper.getNodeObjects().forEach(x => {
				ignoredNodeIds.push(x.getId());
			});
		} else {
			pathObjects.getChildren().forEach(x => {
				if (x.getType() === 'node') {
					ignoredNodeIds.push(x.getId());
				}
			});
		}

		return ignoredNodeIds;
	}

	/**
	 * Gets the nearest possible snap option for the mouse coordinates
	 * @param mouseCoords the mouse coordinates
	 * @param waypoint the waypoint that is currently being edited
	 * @returns the nearest possible snap option (accounting for snap priorities)
	 */
	public checkForSnap(mouseCoords: RealWorldCoordinates, waypoint?: Waypoint): SnapOption | undefined {
		const node = this.checkForNodeSnap(mouseCoords, waypoint);
		if (node) {
			return node;
		}

		const bay = this.checkForBaySnap(mouseCoords);
		if (bay) {
			const bayEntity = bay.getEntity();

			return {
				type: 'bay',
				entityId: bayEntity.getModelId(),
				targetLocation: this.getBaySnapLocation(bay),
				targetHeading: bayEntity.heading,
			};
		}

		return undefined;
	}

	private checkForNodeSnap(mouseCoords: RealWorldCoordinates, waypoint?: Waypoint): SnapOption | undefined {
		const ignoredNodeIds = this.getIgnoredNodeIds();

		const nodeGraphics: NodeGraphic[] = this.mapRenderer.getContainer('node').children
			.map(o => this.mapRenderer.getObjectById(o.name!) as NodeGraphic);

		let possibleNodeDistance = Number.MAX_VALUE;
		let possibleNode: NodeGraphic | undefined;
		let isStartEndNode: boolean = false;
		nodeGraphics.forEach(node => {
			if (ignoredNodeIds.includes(node.getId())) {
				return;
			}

			const nodeEntity = node.getEntity();
			const distance = calcDistanceBetweenCoords(nodeEntity.easting, nodeEntity.northing, mouseCoords.easting, mouseCoords.northing);

			const isMidWaypoint = !!waypoint && this.waypoints.isMidWaypoint(waypoint.id);
			if (!isMidWaypoint && isStartEndNode && !nodeEntity.isStartOrEndNodeOfLink()) {
				return;
			}

			if (distance <= possibleNodeDistance && distance <= NODE_RADIUS) {
				possibleNodeDistance = distance;
				possibleNode = node;
				isStartEndNode = nodeEntity.isStartOrEndNodeOfLink();
			}
		});

		if (!possibleNode) {
			return undefined;
		}

		if (!isStartEndNode) {
			const nodeEntity = possibleNode.getEntity();
			return {
				type: 'node',
				entityId: nodeEntity.getModelId(),
				targetLocation: nodeEntity.getCoordinates(),
				targetHeading: possibleNode.getEntity().getHeading(),
			}; 
		}

		return this.snapStartOrEndPoint(possibleNode, waypoint);
	}

	private checkForBaySnap(mouseCoords: RealWorldCoordinates): Bay | undefined {
		const bayGraphics: Bay[] = this.mapRenderer.getContainer('bay').children
			.map(o => this.mapRenderer.getObjectById(o.name!) as Bay);

		let possibleBayDistance = Number.MAX_VALUE;
		let possibleBay: Bay | undefined;
		bayGraphics.forEach(bay => {
			const bayLocation = bay.getBayLocation();
			const distance = calcDistanceBetweenCoords(bayLocation.easting, bayLocation.northing, mouseCoords.easting, mouseCoords.northing);

			if (distance < possibleBayDistance && distance <= BAY_RADIUS) {
				possibleBayDistance = distance;
				possibleBay = bay;
			}
		});

		return possibleBay;
	}

	private getBaySnapLocation(bay: Bay): RealWorldCoordinates {
		const bayEntity = bay.getEntity();
		const location = bay.getBayLocation();
		const heading = bayEntity.heading;

		const offset = offsetAlongHeadingRealWorld(-this.truckOffset, heading);

		return realWorldCoordinates(
			location.northing + offset.northing,
			location.easting + offset.easting);
	}

	private snapStartOrEndPoint(node: NodeGraphic, waypoint?: Waypoint): SnapOption | undefined {
		const targetNode = node.getEntity();

		if (targetNode.isStartNodeOfLink()) {
			console.log('snapToStartNode');
			return this.snapToStartNode(node, waypoint);
		} else if (targetNode.isEndNodeOfLink()) {
			console.log('snapToEndNode');
			return this.snapToEndNode(node, waypoint);
		}

		return undefined;
	}

	private snapToStartNode(node: NodeGraphic, waypoint?: Waypoint): SnapOption | undefined {
		// We can't snap to a start node if we haven't placed the start waypoint yet
		if (!this.waypoints.hasWaypoint() || (!!waypoint && this.waypoints.lastWaypoint?.id !== waypoint.id)) {
			return undefined;
		}

		const targetNode = node.getEntity();

		const waypointTask = waypoint?.task ?? this.propertiesHelper.getExpectedTaskForWaypoint();

		if (!targetNode.isAllowedToBeSnapped()) {
			return undefined;
		}

		if (!this.pathManager.validationHelper.isAllowedConnectivityPair(targetNode)) {
			alertToast(CONNECTIVITY_PAIR_ERROR, 'error');
			return undefined;
		}

		const isDirectionsCompatible = targetNode.isPathDirectionCompatible(
			waypoint?.direction ?? this.propertiesHelper.getExpectedDirectionForWaypoint(waypointTask));
		if (!isDirectionsCompatible) {
			// We can't update the direction of the path if these conditions are true
			if (this.pathManager.isEditing()
				|| this.waypoints.containsMidWaypoints()
				|| this.waypoints.isStartSnapped()) {
				return undefined;
			}

			let direction = targetNode.getDirection();

			if (waypointTask !== 'HAULING') {
				direction = reverseDirection(direction);
			}

			this.propertiesHelper.setDirectionPropertyAndUpdate(direction);
		}

		const direction = waypoint?.direction ?? this.propertiesHelper.getExpectedDirectionForWaypoint(waypointTask);

		const snapDistance = waypointTask !== 'HAULING' ? this.nodeMinDistance : NODE_SNAP_DISTANCE;
		const snapDirection = targetNode.getEndNodePlacementDirection(direction);
		const snapHeading = targetNode.getHeading();

		// Special case for parking nodes. The truck can't leave a parked node in reverse
		if (targetNode.getDirection() === 'reverse' && waypointTask === 'PARKING') {
			return undefined;
		}

		const targetLocation = getOffsetAlongHeading(
			targetNode.getCoordinates(),
			snapDistance * snapDirection,
			snapHeading
		);

		const linkId = targetNode.getLink()?.getModelId();
		if (!linkId) {
			return undefined;
		}

		return {
			type: 'start',
			entityId: linkId,
			targetLocation: targetLocation,
			targetHeading: snapHeading,
		};
	}

	private snapToEndNode(node: NodeGraphic, waypoint?: Waypoint): SnapOption | undefined {
		const targetNode = node.getEntity();
		const waypointTask = this.propertiesHelper.getExpectedTaskForWaypoint();

		if ((!!waypoint && this.waypoints.firstWaypoint?.id !== waypoint.id) || (!waypoint && this.waypoints.hasWaypoint())) {
			return undefined;
		}

		if (!targetNode.isAllowedToBeSnapped()) {
			return undefined;
		}

		if (!this.pathManager.validationHelper.isAllowedConnectivityPair(targetNode)) {
			alertToast(CONNECTIVITY_PAIR_ERROR, 'error');
			return undefined;
		}

		const isDirectionsCompatible = targetNode.isPathDirectionCompatible(
			waypoint?.direction ?? this.propertiesHelper.getExpectedDirectionForWaypoint(waypointTask));
		if (!isDirectionsCompatible) {
			if (this.pathManager.isEditing() || waypoint !== undefined) {
				return undefined;
			}

			let direction = targetNode.getDirection();
			if (targetNode.task === 'PARKING') {
				direction = 'forward';
			}

			this.propertiesHelper.setDirectionPropertyAndUpdate(direction);
		}

		const direction = waypoint?.direction ?? this.propertiesHelper.getExpectedDirectionForWaypoint(waypointTask);

		const snapDistance = targetNode.isSpecialTask() ? this.nodeMinDistance : NODE_SNAP_DISTANCE;
		const snapDirection = targetNode.getStartNodePlacementDirection(direction);
		const snapHeading = targetNode.getHeading();

		const targetLocation = getOffsetAlongHeading(
			targetNode.getCoordinates(),
			snapDistance * snapDirection,
			snapHeading
		);

		const linkId = targetNode.getLink()?.getModelId();
		if (!linkId) {
			return undefined;
		}

		return {
			type: 'end',
			entityId: linkId,
			targetLocation: targetLocation,
			targetHeading: targetNode.getHeading(),
		};
	}
}