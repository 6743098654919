import classNames from 'classnames';
import ConnectivityValidator, {
    CONNECTIVITY_INVALID_DIRECTION,
    CONNECTIVITY_INVALID_DISTANCE
} from "../Map/MapValidators/ConnectivityValidator";
import {LinkEntity, LinkFromLinkTo, MapToolParamEntity} from "../../../Models/Entities";
import MapController from "../Map/MapController";
import InputField from "./PropertiesPanelComponents/InputField";
import {useState} from "react";
import {observable, runInAction} from "mobx";
import {connectivityOptions} from "../../../Models/Enums";
import alertToast from "../../../Util/ToastifyUtils";
import LinkOperationsHelper, {updateLinkSublinkNodeStates} from "../Map/MapStateHandlerHelpers/LinkOperationsHelper";
import {getAffectedLinks} from "../Map/Helpers/DrivingZone";
import PathToolHelper from "../Map/MapStateHandlerHelpers/PathToolHelper";
import CreateConnectivityCommand from "../ChangeTracker/ChangeTypes/CreateConnectivityCommand";
import UpdateConnectivityCommand from "../ChangeTracker/ChangeTypes/UpdateConnectivityCommand";
import {Button, Colors, Display} from "../../Components/Button/Button";
import {RenderInformationCombobox} from "./PropertiesPanelComponents/RenderInformationCombobox";
import {NumberTextField} from "../../Components/NumberTextBox/NumberTextBox";
import {handleOnEnter} from "./PropertiesSidePanel";
import ConnectivityToolHandler, {
    CONNECTIVITY_INVALID_NODE,
    CONNECTIVITY_PAIR
} from "../Map/MapStateHandlers/ConnectivityToolHandler";

// making error messages as a constant to keep track of error messages
const LINK_ID_DOES_NOT_EXIST = 'This Link ID does not exist. Please enter a valid Link ID.';
const LINK_ID_NEGATIVE = 'A Link ID must be a positive number between 1 and [StaticLinkIDMax from map params]. Please enter a valid Link ID.';
const LINK_ID_CONNECTIVITY_WITH_ITSELF = 'A Link cannot have a connectivity with itself. Please enter a valid Link ID.';
const LINK_ID_EXISTING = 'This connectivity already exist. Please enter a valid Link ID.';

/**
 * Render connectivity table
 * @constructor
 */
export const RenderConnectivityTable = ({previousLinks, validateConnectivity, link, action, handleDeleteConnectivity, nextLinks} : {
    previousLinks : LinkEntity[],
    validateConnectivity: (currentLink: LinkEntity, linkIdToBeConnected: number, state: string, action: string,
                           linkIdTobeRemoved: string, previousLinks: LinkEntity[], nextLinks: LinkEntity[], mapParams: MapToolParamEntity, map: MapController,
                           setError: React.Dispatch<React.SetStateAction<string>>,
                           addConnectivity: (startNodeLink: LinkEntity, endNodeLink: LinkEntity, isEditAction?: boolean, lfltToBeRemoved?: LinkFromLinkTo) => boolean,
                           setPreviousLinks: React.Dispatch<React.SetStateAction<LinkEntity[]>>,
                           setNextLinks: React.Dispatch<React.SetStateAction<LinkEntity[]>>) => string,
    link: LinkEntity, action: string,
    handleDeleteConnectivity: (linkToBeRemoved: LinkEntity, currentLink: LinkEntity, state: string) => void,
    nextLinks: LinkEntity[]
}) => {
    return (
        <div className="connectivity-table">
            <div className="properties-column">
                <div className="label">
                    <p>Previous Link ID</p>
                </div>
                {previousLinks?.length === 0 ? <div className="disabled-info-fields"> None </div>
                    : !!previousLinks && previousLinks.map(p => {
                    const model = { linkId: p.linkId };
                    return (
                        <div className="info-fields" key={p.id ?? p._clientId}>
                            <InputField
                                isNumber
                                key={p.id ?? p._clientId}
                                model={model}
                                modelProperty="linkId"
                                onValidateInput={(value: any) => validateConnectivity(link, value,
                                    'previous', action, p.id)}
                                maxLength={5}
                            />
                            <span
                                key={p.id + "_minus" ?? p._clientId + "_minus"}
                                className={classNames('icon', 'icon-only', 'icon-minus', 'connnectivity-icon')}
                                onClick={() => { handleDeleteConnectivity(p, link, 'previous'); }}
                            />
                        </div>
                    );
                })}
            </div>

            <div className="properties-column">
                <div className="label">
                    <p>Next Link ID</p>
                </div>
                {
                    nextLinks?.length === 0 ? <div className="disabled-info-fields"> None </div>
                        : !!nextLinks && nextLinks.map(p => {
                        const model = { linkId: p.linkId };
                        return (
                            <div className="info-fields" key={p.id ?? p._clientId}>
                                <InputField
                                    isNumber
                                    key={p.id ?? p._clientId}
                                    model={model}
                                    modelProperty="linkId"
                                    onValidateInput={
                                        (value: any) => validateConnectivity(link, value,
                                            'next', action, p.id)
                                    }
                                    maxLength={5}
                                />
                                <span
                                    key={p.id + "_minus" ?? p._clientId + "_minus"}
                                    className={classNames('icon', 'icon-only', 'icon-minus', 'button-width', 'connectivity-icon')}
                                    onClick={() => { handleDeleteConnectivity(p, link, 'next'); }}
                                />
                            </div>
                        );
                    })

                }
            </div>
        </div>
    );
};


/**
 * Add Connectivity tab
 * @param currentLink
 * @param hidePanel
 * @constructor
 */
export function AddConnectivity({ currentLink, hidePanel, map, nextLinks, previousLinks, mapParams, link, setNextLinks, setPreviousLinks } :
                             { currentLink: LinkEntity, hidePanel: () => void, map: MapController, nextLinks: LinkEntity[], previousLinks: LinkEntity[],
                                 mapParams: MapToolParamEntity, link: LinkEntity, setNextLinks: React.Dispatch<React.SetStateAction<LinkEntity[]>>,
                                 setPreviousLinks: React.Dispatch<React.SetStateAction<LinkEntity[]>>
                             }) {

    const [options, setOptions] = useState(observable({
        selected: 'PREVIOUS',
        linkId: undefined,
    }));

    const connectivityOptionsCombobox = Object
        .entries(connectivityOptions)
        .map(([value, display]) => ({ display, value }));

    const [error, setError] = useState('');

    const handleValidateConnectivity = () => {
        // Some of the validations here had better to be integrated into validateConnectivity
        if (!options.linkId || !map?.getMapLookup().getLinkByIdNumber(parseInt(options.linkId, 10))) {
            alertToast('This Link ID does not exist. Please enter a valid Link ID.', 'error');
            setError('error');
            return false;
        }

        const linkToBeConnected = options.linkId ? map?.getMapLookup().getLinkByIdNumber(parseInt(options.linkId, 10)) : undefined;
        if (!linkToBeConnected) {
            setError('error');
            return false;
        }

        const connectivityValidationResult = validateConnectivity(
            currentLink, options.linkId, options.selected, 'add', linkToBeConnected.id,
            previousLinks, nextLinks, mapParams, map, setError, addConnectivity, setPreviousLinks, setNextLinks);
        if (connectivityValidationResult !== 'true') {
            alertToast(connectivityValidationResult, 'error');
            setError('error');
            return false;
        }

        if (options.selected.toLowerCase() === 'previous') {
            if (!validateConnectivityDistance(currentLink, linkToBeConnected, map, true)) {
                setError('error');
                return false;
            }
        }

        if (options.selected.toLowerCase() === 'next') {
            if (!validateConnectivityDistance(linkToBeConnected, currentLink, map, true)) {
                setError('error');
                return false;
            }
        }
        setError('');
        return true;
    };

    const handleAdd = () => {

        if (!handleValidateConnectivity()) {
            return;
        }

        const linkToBeConnected = map?.getMapLookup().getLinkByIdNumber(parseInt(options.linkId!, 10));

        if (options.selected.toLowerCase() === 'next') {
            addConnectivity(linkToBeConnected!, currentLink)
            setNextLinks(link.nextLinks());
        }
        if (options.selected.toLowerCase() === 'previous') {
            addConnectivity(currentLink, linkToBeConnected!)
            setPreviousLinks(link.previousLinks());
        }
    };

    /**
     * TODO: Add action don't need to pass linkIdTobeRemoved, this should be refactor
     * Validate connectivity.
     * Without validating connectivity distance if it is 'add' action.
     * @param currentLink The link that is selected
     * @param linkIdToBeConnected The linkIdNumber that is to be connected
     * @param state Previous or Next
     * @param _action Add or Edit
     * @param linkIdTobeRemoved If 'add', this is the id of a link that is to be connected. If 'edit', this is the id of a link that is to be removed.
     */
// eslint-disable-next-line max-len


    /**
     * Add connectivity for the given two links, update link state(s), and
     * emit onTrackAddConnectivity or onTrackUpdateConnectivity event.
     * @param startNodeLink: The link containing the start node of the connectivity (not the first link in the path)
     * @param endNodeLink: The link containing the end node of the connectivity (not the final link in the path)
     * @param isEditAction
     * @param lfltToBeRemoved if isEditAction is true, provide connectivity need to be removed.
     */
    function addConnectivity(startNodeLink: LinkEntity, endNodeLink: LinkEntity, isEditAction?: boolean, lfltToBeRemoved?: LinkFromLinkTo): boolean {
        const _startNodeLink = LinkOperationsHelper.copyLink(startNodeLink);
        const _endNodeLink = LinkOperationsHelper.copyLink(endNodeLink);

        // add connectivity
        const newLinkFrom = new LinkFromLinkTo({
            linkFrom: endNodeLink, linkFromId: endNodeLink.id, linkTo: startNodeLink, linkToId: startNodeLink.id,
        });
        newLinkFrom.id = newLinkFrom._clientId;
        startNodeLink.linkFroms.push(newLinkFrom);
        endNodeLink.linkTos.push(newLinkFrom);

        if (startNodeLink && endNodeLink && map) {
            updateLinkSublinkNodeStates(startNodeLink, map.getEventHandler());
            updateLinkSublinkNodeStates(endNodeLink, map.getEventHandler());
            // map.getEventHandler().emitPathEditedEvent();

            /**
             * Emit the event to render the connectivity graphic for the updated link
             */
            map.getEventHandler().emit('requestUpdate', link);
        }
        if (!isEditAction) {
            console.log('emit add connectivity event');
            map.getTracker().addChange(new CreateConnectivityCommand(endNodeLink.id, startNodeLink.id));
        } else if (isEditAction && lfltToBeRemoved) {
            console.log('emit update connectivity event');

            map.getTracker().addChange(new UpdateConnectivityCommand(lfltToBeRemoved.linkFromId, lfltToBeRemoved.linkToId, endNodeLink.id, startNodeLink.id));
        }

        return true;
    }

    return (
        <div className="connectivity-sidebar-popout">
            <div className="additional-properties-input">
                <div className="section-header">
                    <h6>Add Connectivity</h6>
                    <Button
                        className="close-button no-background"
                        icon={{ icon: 'cross', iconPos: 'icon-bottom' }}
                        colors={Colors.White}
                        display={Display.Text}
                        onClick={() => {
                            setError('');
                            hidePanel();
                        }}
                    />
                </div>
                <div className="section-divider" />

                <RenderInformationCombobox
                    model={options}
                    label="Position"
                    modelProperty="selected"
                    options={connectivityOptionsCombobox}
                    onAfterChange={() => {
                        runInAction(() => {
                            setOptions(prevState => {
                                return { ...prevState, selected: options.selected };
                            });
                        });
                    }}
                    inputProps={{
                        onKeyDown: handleOnEnter(() => handleAdd())
                    }}
                />

                <NumberTextField
                    className={error === 'error' ? 'add-connectivity-link-id-error' : 'add-connectivity-link-id'}
                    model={options}
                    label="Link ID"
                    modelProperty="linkId"
                    inputProps={{
                        maxLength: 5,
                        onKeyDown: handleOnEnter(() => handleAdd())
                    }}
                />

                <Button type="submit" className="connectivity-submit btn--primary" onClick={handleAdd}>
                    Add
                </Button>
            </div>
        </div>
    );
}

export function validateConnectivity(
    currentLink: LinkEntity,
    linkIdToBeConnected: number,
    state: string,
    _action: string,
    linkIdTobeRemoved: string, previousLinks: LinkEntity[],
    nextLinks: LinkEntity[],
    mapParams: MapToolParamEntity,
    map: MapController,
    setError: React.Dispatch<React.SetStateAction<string>>,
    addConnectivity: (startNodeLink: LinkEntity, endNodeLink: LinkEntity, isEditAction?: boolean, lfltToBeRemoved?: LinkFromLinkTo) => boolean,
    setPreviousLinks: React.Dispatch<React.SetStateAction<LinkEntity[]>>,
    setNextLinks: React.Dispatch<React.SetStateAction<LinkEntity[]>>,
): string {
    let matchingExistingConnectivity: boolean[] | undefined;
    let count = 0;
    let errorStatus = 'false';

    // check if the link id already exist in previous or next column
    if (state.toLowerCase() === 'previous') {
        matchingExistingConnectivity = previousLinks?.map((id: LinkEntity) => id.linkId === linkIdToBeConnected);
    }
    if (state.toLowerCase() === 'next') {
        matchingExistingConnectivity = nextLinks?.map((id: LinkEntity) => id.linkId === linkIdToBeConnected);
    }

    if (!!matchingExistingConnectivity) {
        matchingExistingConnectivity.forEach((value: boolean) => {
            if (value) {
                count += 1;
            }
        });
    }

    const linkToBeConnected = map?.getMapLookup().getLinkByIdNumber(linkIdToBeConnected);

    // validations
    if (!linkIdToBeConnected || !linkToBeConnected) {
        return LINK_ID_DOES_NOT_EXIST;
    }

    if (linkIdToBeConnected < 0 || linkIdToBeConnected > mapParams?.staticLinkIdMax) {
        return LINK_ID_NEGATIVE;
    }

    const startNode = state.toLowerCase() === 'previous' ? currentLink.firstNode() : linkToBeConnected.firstNode();
    if (startNode?.task !== 'HAULING') {
        return CONNECTIVITY_INVALID_NODE;
    }

    if (linkIdToBeConnected === currentLink.linkId) {
        return LINK_ID_CONNECTIVITY_WITH_ITSELF;
    }

    if ((count === 1)) {
        return LINK_ID_EXISTING;
    }

    // The following code is for 'edit'
    const linkTobeRemoved = map?.getMapLookup().getEntity(linkIdTobeRemoved, LinkEntity);
    if (!linkTobeRemoved) {
        return 'The link to be removed does not exist';
    }

    // Add action: check the link that is to be added
    // Edit action: check the link that is to be removed
    // If the removal passes validation, it means there are no connectivity pairs, which allows a new one to be added
    const _linkToBeChecked = _action === 'add' ? linkToBeConnected : linkTobeRemoved;
    const startNodeLink = state.toLowerCase() === 'next' ? _linkToBeChecked : currentLink;
    const endNodeLink = state.toLowerCase() === 'previous' ? _linkToBeChecked : currentLink;
    const hasConnectivityPair = ConnectivityToolHandler
        .hasConnectivityPair(startNodeLink, endNodeLink, map.getMapLookup(), _action);
    if (hasConnectivityPair) {
        return CONNECTIVITY_PAIR;
    }

    setError('');
    errorStatus = 'true';
    const isEditAction = _action === 'edit';
    if (isEditAction) {
        console.log('Inital entity id', linkToBeConnected.getModelId());
        if (state.toLowerCase() === 'previous') {
            if (!!linkToBeConnected) {
                // for undo/redo actions, removing the existing connectivity -> we need to pass a LinkFromLinkTo Model
                // Hence, fetching the LinkFromLinkTo from the LinkFroms
                const lfltToBeRemoved = currentLink.linkFroms.find(
                    item => (
                        item.linkToId === currentLink?.getModelId()) && (item.linkFromId === linkTobeRemoved?.getModelId()
                    ),
                );
                if (lfltToBeRemoved) {
                    lfltToBeRemoved.getModelId();
                }

                // validate connectivity distance before removing the previous link
                if (!validateConnectivityDistance(currentLink, linkToBeConnected, map, false)) {
                    return CONNECTIVITY_INVALID_DISTANCE;
                }

                if (!validateConnectivityDirections(linkToBeConnected, currentLink, map)) {
                    return CONNECTIVITY_INVALID_DIRECTION;
                }

                currentLink.removePreviousLink(linkIdTobeRemoved);
                addConnectivity(currentLink, linkToBeConnected, isEditAction, lfltToBeRemoved);
                setPreviousLinks(currentLink.previousLinks());
            } else {
                errorStatus = 'Link not found.';
            }
        }

        if (state.toLowerCase() === 'next') {
            if (!!linkToBeConnected) {
                // for undo/redo actions, removing the existing connectivity -> we need to pass a LinkFromLinkTo Model
                // Hence, fetching the LinkFromLinkTo from the LinkFroms
                const lfltToBeRemoved = currentLink.linkTos.find(
                    item => (
                        item.linkFromId === currentLink?.getModelId()) && (item.linkToId === linkTobeRemoved?.getModelId()
                    ),
                );
                if (lfltToBeRemoved) {
                    lfltToBeRemoved.getModelId();
                }

                // validate connectivity distance before removing the next link
                if (!validateConnectivityDistance(linkToBeConnected, currentLink, map, false)) {
                    return CONNECTIVITY_INVALID_DISTANCE;
                }

                if (!validateConnectivityDirections(linkToBeConnected, currentLink, map)) {
                    return CONNECTIVITY_INVALID_DIRECTION;
                }

                currentLink.removeNextLink(linkIdTobeRemoved);
                addConnectivity(linkToBeConnected, currentLink, isEditAction, lfltToBeRemoved);
                setNextLinks(currentLink.nextLinks());
            } else {
                errorStatus = 'Link not found.';
            }
        }
    }

    return errorStatus;
}

/**
 * Validate connectivity distance via two nodes
 * @param startNodeLink: The link containing the start node of the connectivity (not the first link in the path)
 * @param endNodeLink: The link containing the end node of the connectivity (not the final link in the path)
 * @param showErrorToast
 */
function validateConnectivityDistance(startNodeLink: LinkEntity, endNodeLink: LinkEntity, map: MapController,
                                      showErrorToast: boolean = true) {
    if (!!map) {
        const startNode = map.getMapLookup().getFirstNodeForLink(startNodeLink.id ?? startNodeLink._clientId);
        const lastSublinkOfFromLink = endNodeLink.sublinkss?.find(sublink => !sublink.getNextSublink() || false);
        if (!!lastSublinkOfFromLink) {
            const endNode = lastSublinkOfFromLink.getLastNode();
            if (!!startNode && !!endNode) {
                const isValidDistance = ConnectivityValidator.validateConnectivityDistance(
                    startNode,
                    endNode,
                    map,
                    showErrorToast,
                );
                if (!isValidDistance) {
                    return false;
                }
            }
        }
    }
    return true;
}

function validateConnectivityDirections(startNodeLink: LinkEntity, endNodeLink: LinkEntity, map: MapController) {
    if (!!map) {
        const startNode = map.getMapLookup().getFirstNodeForLink(startNodeLink.id ?? startNodeLink._clientId);
        const lastSublinkOfFromLink = endNodeLink.sublinkss?.find(sublink => !sublink.nextSublink || false);
        if (!!lastSublinkOfFromLink) {
            const endNode = lastSublinkOfFromLink.getLastNode();
            if (!!startNode && !!endNode) {
                const isValidDirection = ConnectivityValidator
                    .validateConnectivityDirections(endNode, startNode, map.getMapLookup());
                if (!isValidDirection) {
                    return false;
                }
            }
        }
    }
    return true;
}
