import MapController from "../Map/MapController";
import {convertToFixed, IClothoidParams} from "./PropertiesSidePanel";
import React, {useEffect, useMemo, useState} from "react";
import {RenderInformationCombobox} from "./PropertiesPanelComponents/RenderInformationCombobox";
import InputField from "./PropertiesPanelComponents/InputField";
import {RenderToggleButton} from "./PropertiesPanelComponents/RenderToggleButton";
import {store} from "../../../Models/Store";
import {nodetaskOptions} from "../../../Models/Enums";
import {
	EASTING_DEFAULT_VALUE, EASTING_INSIDE_BOUNDS_ERROR,
	EASTING_LESS_THAN_ZERO_ERROR,
	HEADING_ERROR,
	NORTHING_DEFAULT_ERROR, NORTHING_INSIDE_BOUNDS_ERROR, NORTHING_LESS_THAN_ZERO_ERROR
} from "../../../Constants";
import {get, runInAction} from "mobx";
import {observer} from "mobx-react";
import NodeValidator from "../Map/MapValidators/NodeValidator";
import {Waypoint, WaypointList} from "../Map/MapStateHandlers/PathTool/Helpers/Waypoint";
import ErrorsAndWarningsProperties from "./ErrorsAndWarningsProperties";

const HCM_PG_SET_KEY = 'isHcmPgSet';

export interface IPathPropertiesProps {
	waypoints: WaypointList;
	map: MapController;
	clothoidParams?: Required<IClothoidParams>;
}

const PathProperties = observer((props: IPathPropertiesProps) => {
	const { waypoints, map, clothoidParams: pathParams } = props;

	if (pathParams == null) {
		throw new Error('Invalid path property parameters');
	}

	const isTaskReadOnly = get(pathParams, 'isTaskReadOnly');
	const isWaypointLocationReadOnly = get(pathParams, 'isWaypointLocationReadOnly');
	const isHCMPGButtonReadonly = get(pathParams, 'isHCMPGButtonReadonly');
	const isDirectionReadOnly = get(pathParams, 'isDirectionReadOnly');

	const [isHCMPG, setIsHCMPG] = useState(localStorage[HCM_PG_SET_KEY] === 'true');
	store.isHCMPGEnabled = isHCMPG;

	const startWaypoint = waypoints.firstWaypoint ?? new Waypoint();
	const endWaypoint = waypoints.lastWaypointForPropertiesPanel ?? new Waypoint();
	
	const getDirectionOptions = () => {
		const directionOptions = [
			{ display: 'Forward', value: 'forward' },
			{ display: 'Reverse', value: 'reverse' }
		];

		const mixed = { display: 'Mixed', value: 'mixed' };

		if (pathParams.isDirectionReadOnly) {
			directionOptions.push(mixed);
		}

		return directionOptions;
	};

	const taskOptions = useMemo(() =>
		['HAULING', 'REVERSEPOINT', 'PARKING'].map(v => ({ display: nodetaskOptions[v], value: v })),
		[]
	);

	const toggleHCMPG = () => {
		const isHCMPGEnabled = !isHCMPG;
		store.isHCMPGEnabled = isHCMPGEnabled;
		localStorage[HCM_PG_SET_KEY] = isHCMPGEnabled;
		setIsHCMPG(isHCMPGEnabled);
	};

	const onValidateHeading = (value: number): string | undefined => {
		if (!NodeValidator.validateHeading(value)) {
			return HEADING_ERROR;
		}
		return undefined;
	};

	const onValidateCoordinates = (value: number, waypoint: Waypoint, property: 'northing' | 'easting') => {
		const isEasting = property === 'easting';

		const northing = isEasting ? waypoint.northing : value;
		const easting = !isEasting ? waypoint.easting : value;

		if (easting == undefined || northing == undefined) {
			return isEasting ? EASTING_DEFAULT_VALUE : NORTHING_DEFAULT_ERROR;
		}

		if (easting < 0 || northing < 0) {
			return isEasting ? EASTING_LESS_THAN_ZERO_ERROR : NORTHING_LESS_THAN_ZERO_ERROR;
		}

		const coords = {
			easting,
			northing
		};
		if (!map.getEventHandler().getRenderer().isPointInMapBounds(coords)) {
			return isEasting ? EASTING_INSIDE_BOUNDS_ERROR : NORTHING_INSIDE_BOUNDS_ERROR;
		}

		return undefined;
	};

	const updateClothoid = () => {
		map?.getEventHandler().emit('requestUpdate');
	};

	return (
		<>
			<h6>Path Properties</h6>
			<RenderInformationCombobox
				model={pathParams}
				label="Direction"
				modelProperty="direction"
				options={getDirectionOptions()}
				onAfterChange={updateClothoid}
				readonly={isDirectionReadOnly}
			/>
			<div className="sub-heading">
				<p>Start Waypoint</p>
			</div>
			<InputField
				model={startWaypoint}
				label="Easting"
				modelProperty="easting"
				propertyUnit="m"
				isNumber
				isReadOnly={isWaypointLocationReadOnly}
				renderDisplayValue={value => convertToFixed(value, 2)}
				onValidateInput={onValidateCoordinates}
				onUpdate={updateClothoid}
			/>
			<InputField
				model={startWaypoint}
				label="Northing"
				modelProperty="northing"
				propertyUnit="m"
				isNumber
				isReadOnly={isWaypointLocationReadOnly}
				renderDisplayValue={value => convertToFixed(value, 2)}
				onValidateInput={onValidateCoordinates}
				onUpdate={updateClothoid}
			/>
			<InputField
				model={startWaypoint}
				label="Elevation"
				modelProperty="elevation"
				propertyUnit="m"
				isNumber
				renderDisplayValue={value => convertToFixed(value, 2)}
				isReadOnly
			/>
			<InputField
				model={startWaypoint}
				label="Heading"
				modelProperty="heading"
				propertyUnit="°"
				isNumber
				alterValueBeforeConfirm={(value) => value % 360}
				renderDisplayValue={value => convertToFixed(value, 1)}
				isReadOnly={isWaypointLocationReadOnly}
				onValidateInput={value => onValidateHeading(value)}
				onUpdate={updateClothoid}
			/>
			<div className="sub-heading">
				<p>End Waypoint</p>
			</div>
			<InputField
				model={endWaypoint}
				label="Easting"
				modelProperty="easting"
				propertyUnit="m"
				isNumber
				isReadOnly={isWaypointLocationReadOnly}
				renderDisplayValue={value => convertToFixed(value, 2)}
				onValidateInput={onValidateCoordinates}
				onUpdate={updateClothoid}
			/>
			<InputField
				model={endWaypoint}
				label="Northing"
				modelProperty="northing"
				propertyUnit="m"
				isNumber
				isReadOnly={isWaypointLocationReadOnly}
				onUpdate={updateClothoid}
				renderDisplayValue={value => convertToFixed(value, 2)}
				onValidateInput={onValidateCoordinates}
			/>
			<InputField
				model={endWaypoint}
				label="Elevation"
				modelProperty="elevation"
				propertyUnit="m"
				isNumber
				renderDisplayValue={value => convertToFixed(value, 2)}
				isReadOnly
			/>
			<InputField
				model={endWaypoint}
				label="Heading"
				modelProperty="heading"
				propertyUnit="°"
				isNumber
				renderDisplayValue={value => convertToFixed(value, 1)}
				isReadOnly={isWaypointLocationReadOnly}
				alterValueBeforeConfirm={v => v % 360}
				onValidateInput={value => onValidateHeading(value)}
				onUpdate={updateClothoid}
			/>
			<RenderInformationCombobox
				model={pathParams}
				label="Task"
				modelProperty="nextWaypointTask"
				options={taskOptions}
				onAfterChange={updateClothoid}
				readonly={isTaskReadOnly}
			/>
			<RenderToggleButton
				label="Optimize Path"
				isChecked={isHCMPG}
				onChange={toggleHCMPG}
				isDisabled={isHCMPGButtonReadonly}
			/>

			<div className="section-divider" />
			<ErrorsAndWarningsProperties errorsAndWarnings={pathParams.errorsAndWarnings}/>
			<div className="section-divider" />
		</>
	);
});

export default PathProperties;