import * as React from 'react';
import MapController, { MapType } from '../Map/MapController';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import If from '../../Components/If/If';
import setObjectDisplayAndRerender from './ObjectsDisplay';
import MapObject from '../Map/MapObjects/MapObject';
import { ISelectedObjectRef } from './LayersPanel';
import { IMenuShownStatus } from '../EditMap';
import Signal from '../Map/MapObjects/Signal/Signal';
import { setCustomTag } from '../Map/Helpers/MapUtils';

export interface ILayersPanelItem {
	id?: string;

	// Name to display on the tree view
	name: string;

	// Id of the entity
	entityId?: string;

	// Type of object on the map
	mapObjectType?: MapType | 'ahs' | 'fms';

	// Any additional classes to be added to the label
	labelStyling?: string;

	// The original children list (never modified)
	children?: ILayersPanelItem[];

	// Children that match the filter
	filteredChildren?: ILayersPanelItem[];

	// If this item is **not** searchable (All children will be searched)
	skipSearch?: boolean;

	// Is the object displayed on the map
	isDisplayed?: boolean;

	// Is the item visible in the tree
	isVisible?: boolean;

	isNotVisibleWhenEmpty?: boolean;

	// Is the map object newly created
	isNew?: boolean;

	isError?: boolean;

	isWarning?: boolean;
}

export function layersPanelItemId(item: ILayersPanelItem): string {
	if (!!item.id) {
		return item.id;
	}
	return !item.mapObjectType || item.skipSearch ? item.name : `${item.mapObjectType}_${item.name}`;
}

export function isItemRefMatch(ref: ISelectedObjectRef, item: ILayersPanelItem): boolean {
	return item.entityId === ref.entityId && item.mapObjectType === ref.entityType;
}

interface ILayersPanelItemProps {
	objectItem: ILayersPanelItem,
	forceExpand: boolean,
	map: MapController | undefined,
	hierarchyToExpand: string[],
	itemEntityIdToSetActive: ISelectedObjectRef,
	id: string,
	isSearched: boolean,
	nesting?: number,
	itemEntityIdToSetInactive?: ISelectedObjectRef,
	isViewMenuShownInLayersPanel: IMenuShownStatus,
}

/**
 * Render layers panel items recursively
 */
export function LayersPanelItem(props: ILayersPanelItemProps) {
	const {
		objectItem,
		map,
		nesting,
		isSearched,
		hierarchyToExpand,
		itemEntityIdToSetActive,
		itemEntityIdToSetInactive,
		id,
		isViewMenuShownInLayersPanel,
	} = props;

	// Use filtered children list if it is not undefined (in the case of searching)
	const children = objectItem.filteredChildren ?? objectItem.children;
	const hasChildren = !!children && children.length > 0;

	// Is the object shown on the map
	const [displayGraphic, setDisplayGraphic] = useState(objectItem.isDisplayed ?? true);

	// Is the object show button visible
	const [showDisplayGraphic, setShowDisplayGraphic] = useState(false);

	// Is the object active on the map (deprecate the user of this for now)
	const [_isActive, setActive] = useState(false);

	// Are the sub items visible
	const [isExpanded, setExpanded] = useState(false);
	const [deactivatedEntityId, setDeactivatedEntityId] = useState<string>('');
	const [hasError, setHasError] = useState(false);
	const [hasWarning, setHasWarning] = useState(false);

	useEffect(() => {
		if (objectItem.labelStyling?.includes('error')) {
			setHasError(() => true);
		} else {
			setHasError(() => false);
		}

		if (objectItem.labelStyling?.includes('warning')) {
			setHasWarning(() => true);
		} else {
			setHasWarning(() => false);
		}
	}, [objectItem.labelStyling]);

	useEffect(() => {
		setDisplayGraphic(objectItem.isDisplayed ?? true);
	}, [objectItem.isDisplayed]);

	useEffect(() => {
		setExpanded(props.forceExpand);
	}, [props.forceExpand, hierarchyToExpand]);

	useEffect(() => {
		if (!itemEntityIdToSetInactive || !objectItem.entityId) {
			return;
		}

		if (deactivatedEntityId === itemEntityIdToSetInactive.entityId) {
			return;
		}

		if (isItemRefMatch(itemEntityIdToSetInactive, objectItem)) {
			setActive(false);
		}
	}, [itemEntityIdToSetInactive]);

	useEffect(() => {
		setActive(false);

		if (!itemEntityIdToSetActive || !objectItem.entityId) {
			return;
		}

		if (itemEntityIdToSetActive === itemEntityIdToSetInactive) {
			return;
		}

		if (isItemRefMatch(itemEntityIdToSetActive, objectItem)) {
			setActive(true);
			if (itemEntityIdToSetActive.selectedViaLayers !== true) {
				const sidePanel = document.getElementById('object-sidebar-panel');

				const sidePanelHeight = sidePanel?.getBoundingClientRect().height;

				const element = document.getElementById(id);
				const elementTop = element?.offsetTop;

				const checkErrorElement = document.getElementsByClassName('map-check-errors')[0];
				const checkErrorElementHeight = checkErrorElement?.getBoundingClientRect().height;

				const toolbars = document.querySelector('.toolbars');
				const toolbarHeight = toolbars?.clientHeight;

				if (!elementTop || !sidePanelHeight || !checkErrorElementHeight || !toolbarHeight) {
					return;
				}

				const topOffset = elementTop - ((sidePanelHeight / 2) + checkErrorElementHeight) + toolbarHeight;

				sidePanel?.scrollTo({
					top: topOffset,
				});
			}
		} else if (objectItem.entityId === map?.getHighlightedEntityId()) {
			setActive(true);
		}
	}, [itemEntityIdToSetActive]);

	// Create the nesting dom structure
	const nestingDom: React.ReactNode[] = [];
	for (let i = 0; i < (nesting ?? 0); i++) {
		nestingDom.push(<span key={`${objectItem.name}-${i}`} className="nesting" />);
	}

	const emitEvents = (mapObject: MapObject<unknown>) => {
		map?.getEventHandler().emit('onPropertiesPanel',
			objectItem.mapObjectType as MapType, mapObject, { onUnselect: () => setActive(false) } );

		switch (mapObject?.getType()) {
			case 'bay':
				map?.getEventHandler().setMapEventState('edit_bay', { mapObject });
				break;
			case 'area':
				map?.getEventHandler().setMapEventState('edit_area', { mapObject });
				break;
			case 'link':
				map?.getEventHandler().setMapEventState('select_confirmed_path', mapObject);
				break;
			default:
				map?.getEventHandler().setMapEventState('selector', { mapObject });
		}
	};

	const onItemLabelClick = (e: React.MouseEvent<HTMLSpanElement>) => {
		if (!map || !objectItem.mapObjectType || !objectItem.entityId) return;

		// Deselect the object
		if (isActive && e.detail === 1) {
			setCustomTag('layers-panel', 'deselect-a-map-object');
			map.getEventHandler().emit('onPropertiesPanel', 'map');
			setActive(false);
			setDeactivatedEntityId(objectItem.entityId);
			map.getEventHandler().emit('onItemSelectedInLayersPanel');
			map.getEventHandler().setActiveTool('selector');
			return;
		}

		const mapObjectId = map.getMapLookup()
			.getMapObjectId(objectItem.entityId, objectItem.mapObjectType);
		const mapObject = map.getMapRenderer().getObjectById(mapObjectId);

		const eventMode = map.getEventHandler().getEventMode();
		const currentSelector = map.getSelectedToolType();

		if (objectItem.entityId === 'unknown') {
			map.getEventHandler().emit('onPropertiesPanel', 'map');
			
			if (eventMode !== 'selector' && currentSelector !== 'selector') {
				setCustomTag('layers-panel', 'select-a-map-object');
				// Handle scenario where user clicks item in layers
				// panel when unconfirmed objects are on map
				map.getEventHandler().emit('onBackToSelector', 'map', mapObject);
				// Also hide the confirm button
				map.getEventHandler().emit('toggleConfirmCreation', false);
			}
		} else {
			// Emit the appropriate events based on the current tool
			if (eventMode !== 'selector' && currentSelector !== 'selector') {
				setCustomTag('layers-panel', 'select-a-map-object');
				// Handle scenario where user clicks item in layers
				// panel when unconfirmed objects are on map
				map.getEventHandler().emit('onBackToSelector',
					objectItem.mapObjectType as MapType,
					mapObject);
				// Also hide the confirm button
				map.getEventHandler().emit('toggleConfirmCreation', false);
			} else {
				setCustomTag('layers-panel', 'select-a-map-object');
				emitEvents(mapObject);
			}

			// If it is a double click
			if (e.detail === 2) {
				setCustomTag('layers-panel', 'double-click-and-pan-to-map-object');
				mapObject.panToObject();
			}
		}
		
		// Emit after setting tool back to selector because it de-selects item in Layers Panel
		setTimeout(() => {
			map.getEventHandler().emit('onItemSelectedInLayersPanel', objectItem.entityId, objectItem.mapObjectType);
		}, 10);
		setActive(true);

		setCustomTag('layers-panel', 'select-a-map-object');
	};

	const getItemLabel = () => (
		<>
			<span
				className={classNames('label', objectItem.labelStyling)}
				onClick={e => onItemLabelClick(e)}
			>
				{objectItem.name}
			</span>
			<span
				className={classNames(
					'show-hide-button',
					(!displayGraphic || showDisplayGraphic ? undefined : 'hidden'),
					'icon-only',
					displayGraphic ? 'icon-visibility-on' : 'icon-visibility-off',
				)}
				onClick={() => {
					const option = !displayGraphic === true ? 'show' : 'hide';
					setCustomTag('layers-panel', `${option}-a-map-object`);

					// Set the map object panel shown status when the show/hide of a link or the link's parent object is toggled
					if (!!map && (objectItem.mapObjectType === 'link' || objectItem.mapObjectType === 'ahs')) {
						const lookup = map.getMapLookup();
						lookup.setChildLinkLayersPanelShownStatus(objectItem, !displayGraphic);
					}

					setObjectDisplayAndRerender(objectItem, map, !displayGraphic, true, isViewMenuShownInLayersPanel);
					setDisplayGraphic(() => !displayGraphic);
				}}
			/>
		</>
	);

	/**
	 * Determines if the given layers panel item is among the hierarchy to expand.
	 * @param item - ILayersPanelItem
	 */
	const isAmongHierarchyToExpand = (item: ILayersPanelItem): boolean => {
		if (hierarchyToExpand.length === 0) {
			return false;
		}
		return hierarchyToExpand.some(x => x === layersPanelItemId(item));
	};

	if (props.objectItem.isNotVisibleWhenEmpty === true
		&& (props.objectItem.children?.length ?? []) === 0) {
		return <></>;
	}

	const isActive = itemEntityIdToSetActive?.entityId === props.objectItem.entityId;

	return (
		<>
			<div
				id={id}
				className={classNames(
					'map-object',
					isActive ? 'item-active' : undefined,
				)}
				onMouseEnter={() => setShowDisplayGraphic(true)}
				onMouseLeave={() => setShowDisplayGraphic(false)}
			>
				{nestingDom}
				<span
					className={
						classNames(
							'expand-button',
							!hasChildren ? 'hidden' : undefined,
							'icon-only',
							isExpanded ? 'icon-triangle-opened' : 'icon-triangle-closed',
							objectItem.isError || hasError ? 'error-red' : objectItem.isWarning || hasWarning ? 'warning-yellow' : null,
						)
					}
					onClick={() => {
						if (hasChildren) {
							setExpanded(!isExpanded);
						}
					}}
				/>
				{getItemLabel()}
			</div>
			<If condition={isExpanded && hasChildren}>
				{
					children?.map((item, index) => (
						<LayersPanelItem
							{...props}
							id={item.name + index.toString()}
							objectItem={item}
							hierarchyToExpand={props.hierarchyToExpand}
							forceExpand={isSearched ? true : isAmongHierarchyToExpand(item)}
							key={item.name + index.toString()}
							nesting={(nesting ?? 0) + 1}
						/>
					))
				}
			</If>
		</>
	);
}
