import { Model } from 'Models/Model';
import {
	AreaEntity,
	BayEntity,
	BeaconEntity,
	DrivingAreaEntity,
	ImportVersionEntity,
	LinkEntity,
	LinkFromLinkTo,
	MapToolParamEntity,
	NodeEntity,
	SegmentEntity,
	SignalSetEntity,
	SublinkEntity,
} from '../../../Models/Entities';
import {
	getModelDisplayName,
	getModelName,
} from '../../../Util/EntityUtils';
import { SUBLINK_ID_MAX } from '../../../Constants';
import { store } from '../../../Models/Store';
import MapObject from './MapObjects/MapObject';
import {
	MapObjectErrorType,
} from './MapController';
import { IMenuShownStatus, initialMenuShownStatus } from '../EditMap';
import DrivingArea from './MapObjects/Area/DrivingArea';
import {mapObjectState} from "../../../Models/Enums";
import {ILayersPanelItem} from "../LayersPanel/LayersPanelItem";

/**
 * Provides a lookup table for all map objects - map object can be found efficiently with the object id
 * (provided from import files)
 */
export type ModelClass = { new(): Model };

export type EntityStore = Record<string, Record<string, unknown>>;

export type ErrorWarningStore = Record<string, Record<string, string[]>>;

export type TempErrorWarningStore = Record<string, string[]>;

export type MapObjectEntityType = AreaEntity | BayEntity | LinkEntity | SublinkEntity | NodeEntity;

export const MapObjectEntities: ModelClass[] = [
	LinkEntity,
	SublinkEntity,
	NodeEntity,
	SignalSetEntity,
	AreaEntity,
	BayEntity,
	SegmentEntity,
	BeaconEntity,
	DrivingAreaEntity,
	LinkFromLinkTo,
];

export interface IDrivingZoneUpdateInner {
	drivingZone: string;
	nodes: NodeEntity[];
}
export interface IDrivingZoneUpdate {
	sublinkId: string;
	drivingZone: string;
	nodes: NodeEntity[];
}

// TODO: in future tickets this will be expanded to cover subsets of labels
export interface ILabelViewStatus {
	allLabels: boolean;
}

// save patches deal with edge case 
export interface ISavePatch {
	actionType: string;
	entityId: string;
	entityType: string;
	attribute: string;
	revertToValue: any;
	actionGroupId: string;
}

export default class MapStore {
	// General entity store
	private entities: EntityStore = {};

	// Performant lookup tables
	private nextNodeRef: Record<string, string> = {}
	private nextSublinkRef: Record<string, string> = {};

	private startNodeBySublinkId: Record<string, NodeEntity> = {};
	private startNodeByLinkId: Record<string, NodeEntity> = {};
	private startSublinkByLinkId: Record<string, SublinkEntity> = {};
	private signalSetByLinkId: Record<string, SignalSetEntity> = {};

	private linkByIdNumber: Record<number, LinkEntity> = {};

	private baysByAreaId: Record<string, string[]> = {};

	private areaByName: Record<string, AreaEntity> = {};

	private entityToMapObject: Record<string, string> = {};

	// Track driving zones that need to be saved
	private drivingZoneUpdate: Record<string, IDrivingZoneUpdateInner> = {};
	// This is used for optimising the performance of checkDrivableArea()
	private drivingAreaObjByDringZoneObjId: Record<string, DrivingArea> = {};

	// Array of map object id's for which dynamic scaling is applied
	private dynamicScaleObjects: string[] = [];

	private _isDisplayDynamicConnections: boolean = false;
	private _isDisplayConnectivityEndpoint: boolean = false;
	private _isDisplaySpeedLimits: boolean = false;

	private entryNodeIds: string[] | undefined = undefined;
	private exitNodeIds: string[] | undefined = undefined;

	private labelViewStatus: ILabelViewStatus = {
		allLabels: true,
	};

	// ID creation store
	private linkIdSearchStart = 1;
	private sublinkIdSearchStart = 1;
	private nodeIdSearchStart = 1;

	// Error store
	private objectErrorCount = 0;
	private mapErrors: ErrorWarningStore = {};
	private oldMapErrorsServerSide: ErrorWarningStore = {};
	private mapErrorsServerSide: ErrorWarningStore = {};

	// Warning store
	private objectWarningCount = 0;
	private mapWarnings: ErrorWarningStore = {};
	private oldMapWarningsServerSide: ErrorWarningStore = {};
	private mapWarningsServerSide: ErrorWarningStore = {};
	private tempMapWarnings: TempErrorWarningStore = {};

	private readonly versionEntity: ImportVersionEntity;
	private readonly mapParamsEntity: MapToolParamEntity;

	private isViewMenuShown: IMenuShownStatus = initialMenuShownStatus;
	private linkLayersPanelShownStatus: Record<string, boolean> = {};
	
	private _isPendingDynamicConnectionUpdate = false;

	private _isCheckPathInterference = false;
	private _isSelectASublinkInterference = false;
	// Array of map object id's that is used for optimising
	private interferePathDrivingZones: string[] = [];

	// Params to deal with clothoid edge case where unwanted request is send hence processing must be blocked
	public blockRequestId: number = 0;
	public currentRequestId: number = 0;

	public confirmButtonWillShow: boolean = false;
	public confirmButtonConfirming: boolean = false;
	
	// 
	private savePatchTable: ISavePatch[] = [];

	constructor(versionEntity: ImportVersionEntity, mapParamsEntity: MapToolParamEntity) {
		this.versionEntity = versionEntity;
		this.mapParamsEntity = mapParamsEntity;
		this.buildLookupTables();
	}

	// related to unique key constraint issue (refer to usage/comment in processBreakSublink)
	public addSavePatch(patch: ISavePatch) {
		const { entityId, entityType, attribute, revertToValue, actionGroupId } = patch;
		console.log(`addSavePatch: entityId ${entityId} entityType ${entityType} attribute: ${attribute} revertToValue actionGroupId ${actionGroupId}`);
		this.savePatchTable.push(patch);
	}

	// related to unique key constraint issue (refer to usage/comment in saveChanges)
	public getSavePatches() {
		return this.savePatchTable;
	}

	// (refer to usage/comment in saveChanges)
	public clearSavePatches(actionGroupIds: string[]) {
		const originalLength = this.savePatchTable.length;
		this.savePatchTable = this.savePatchTable.filter(x => !actionGroupIds.includes(x.actionGroupId));
		const difference = originalLength - this.savePatchTable.length;
		console.log(`clearSavePatches: ${difference} patches removed and ${this.savePatchTable.length} remaining`);
	}

	public set isCheckPathInterference(isChecked: boolean) {
		this._isCheckPathInterference = isChecked;
	}

	public get isCheckPathInterference() {
		return this._isCheckPathInterference;
	}

	public set isSelectASublinkInterference(isSelected: boolean) {
		this._isSelectASublinkInterference = isSelected;
	}

	public get isSelectASublinkInterference() {
		return this._isSelectASublinkInterference;
	}

	public getInterferePathDrivingZones() {
		return this.interferePathDrivingZones;
	}

	public setInterferePathDrivingZones(objIds: string[]) {
		this.interferePathDrivingZones = objIds;
	}

	public set isPendingDynamicConnectionUpdate(update: boolean) {
		this._isPendingDynamicConnectionUpdate = update;
	}

	public get isPendingDynamicConnectionUpdate() {
		return this._isPendingDynamicConnectionUpdate;
	}

	public set isDisplayConnectivityEndpoint(status: boolean) {
		this._isDisplayConnectivityEndpoint = status;
	}

	public get isDisplayConnectivityEndpoint() {
		return this._isDisplayConnectivityEndpoint;
	}

	public set isDisplayDynamicConnections(status: boolean) {
		this._isDisplayDynamicConnections = status;
	}

	public get isDisplayDynamicConnections() {
		return this._isDisplayDynamicConnections;
	}

	public set isDisplaySpeedLimits(status: boolean) {
		this._isDisplaySpeedLimits = status;
	}

	public get isDisplaySpeedLimits() {
		return this._isDisplaySpeedLimits;
	}

	public setLabelViewStatus(labelViewStatus: ILabelViewStatus) {
		this.labelViewStatus = { ...labelViewStatus };
	}

	public getLabelViewStatus() {
		return this.labelViewStatus;
	}

	public setDynamicScaleObjects(objIds: string[]) {
		this.dynamicScaleObjects = objIds;
	}

	public addDynamicScaleObjects(objIds: string[]) {
		// Use set to remove duplicates
		this.dynamicScaleObjects = [...new Set([...this.dynamicScaleObjects, ...objIds])];
	}

	public removeDynamicScaleObjects(objIds: string[]) {
		if (objIds.length > 0) {
			this.dynamicScaleObjects = this.dynamicScaleObjects.filter(x => !objIds.includes(x));
		}
	}

	private commitDynamicConnections = true;

	/**
	 * Use this to set this to false during save operations, or undo/redo operations.
	 * Because in these cases we don't need to check for changes to the dynamic connections, and put them in the change
	 * tracker.
	 *
	 * @param value to set commitDynamicConnections to. true to add differences into the change tracker
	 */
	public setCommitDynamicConnections(value: boolean) {
		this.commitDynamicConnections = value;
	}

	public setEntryAndExitNodeIds(entryObjIds: string[], exitObjIds: string[]) {
		this.entryNodeIds = exitObjIds;
		this.exitNodeIds = entryObjIds;
	}

	public getEntryAndExitNodeIds() {
		if (!this.entryNodeIds) {
			this.entryNodeIds = [];
		}

		if (!this.exitNodeIds) {
			this.exitNodeIds = [];
		}

		return {
			entryNodeIds: this.entryNodeIds,
			exitNodeIds: this.exitNodeIds,
		}
	}

	public resetEntryAndExitNodeIds() {
		this.entryNodeIds = undefined;
		this.exitNodeIds = undefined;
	}

	public hasEntryAndExitNodeIds() {
		return this.entryNodeIds !== undefined || this.exitNodeIds !== undefined;
	}

	public getDynamicScaleObjects() {
		return this.dynamicScaleObjects;
	}

	public getImportVersion(): ImportVersionEntity {
		return this.versionEntity;
	}

	public getMapParameters(): MapToolParamEntity {
		return this.mapParamsEntity;
	}

	/**
	 * Builds lookup tables for links, sublinks, and nodes.
	 * It removes the need to frequently loop through linked lists
	 * to retrieve data (e.g. when building Paths or displaying
	 * link connectivity information)
	 * @param version
	 */
	public buildLookupTables() {
		MapObjectEntities.forEach((x: ModelClass) => {
			this.entities[this.getModelName(x)] = {};
			this.mapErrors[this.getModelName(x)] = {};
			this.mapWarnings[this.getModelName(x)] = {};
		});

		// There is only 5 entity types from server side
		this.oldMapErrorsServerSide['LinkEntity'] = {};
		this.oldMapErrorsServerSide['SublinkEntity'] = {};
		this.oldMapErrorsServerSide['NodeEntity'] = {};
		this.oldMapErrorsServerSide['AreaEntity'] = {};
		this.oldMapErrorsServerSide['BayEntity'] = {};

		this.oldMapWarningsServerSide['LinkEntity'] = {};
		this.oldMapWarningsServerSide['SublinkEntity'] = {};
		this.oldMapWarningsServerSide['NodeEntity'] = {};
		this.oldMapWarningsServerSide['AreaEntity'] = {};
		this.oldMapWarningsServerSide['BayEntity'] = {};

		this.mapWarningsServerSide['LinkEntity'] = {};
		this.mapWarningsServerSide['SublinkEntity'] = {};
		this.mapWarningsServerSide['NodeEntity'] = {};
		this.mapWarningsServerSide['AreaEntity'] = {};
		this.mapWarningsServerSide['BayEntity'] = {};
		
		// to save temporary map warnings before save
		this.tempMapWarnings = {};

		// Build lookup tables for key interactivve entities
		this.versionEntity.linkss.forEach(link => this.addPath(link));
		this.versionEntity.bayss.forEach(bay => this.createEntity(bay));
		this.versionEntity.areass.forEach(area => this.createEntity(area));
		this.versionEntity.drivingAreass.forEach(drivingArea => this.createEntity(drivingArea));

		this.versionEntity.segments.forEach(segment => this.createEntity(segment));
		this.versionEntity.beacons.forEach(beacon => this.createEntity(beacon));
	}

	public createEntity<T extends Model>(obj: T) {
		const createMethodName = `add${obj.getModelDisplayName()}`;
		// console.log(`createEntity: ${createMethodName} ${obj.id}`);
		if (!!this[createMethodName]) {
			this[createMethodName](obj);
		} else {
			// Fallback to the default add method
			this.addEntityInTable(obj);
		}
	}

	public updateEntity<T extends Model>(obj: T, performChange?: (entity: T) => T) {
		console.log(`updateEntity (begin)`);
		// Remove the object
		this.deleteEntity(obj);
		// Perform the change needed on the object. This should be changed in the future to have a history tracker
		// And the old object entity values can be retrieved for the removal of the object
		const newObject = performChange ? performChange(obj) : obj;
		// Re-add the entity
		this.createEntity(newObject);
		const updateMethodName = `update${obj.getModelDisplayName()}`;
		console.log(`updateEntity (end): ${updateMethodName} ${obj.id}`);
		if (!!this[updateMethodName]) {
			// When particular entites are updated (such as NodeEntity), particular lookups
			// need to be re-added after they are re-created
			this[updateMethodName](obj);
		}
	}

	public updateNode(node: NodeEntity) {
		const { sublinkId } = node;

		if (!node.previousNodeId) {
			const link = node.getLink();
			if (!!link && !!sublinkId) {
				this.startNodeBySublinkId[sublinkId] = node;
				this.startNodeByLinkId[link.id] = node;
			}
		} else {
			this.nextNodeRef[node.previousNodeId] = node.id;
		}

		if (!!sublinkId) {
			// Update the sublink to reference this updated node
			const sublink = this.getSublinkById(sublinkId);
			const index = sublink.nodess.findIndex(x => x.id === node.id);
			sublink.nodess[index] = node;
			node.sublink = sublink;
			// Updates nodeRef based on the node information that has itself as the previousNodeId
			const nextNode = sublink.nodess.findIndex(x => x.previousNodeId === node.id);
			if (nextNode !== -1) {
				this.nextNodeRef[node.id] = sublink.nodess[nextNode].id;
			}
		}
	}

	public markEntityToUpdate<T extends Model>(obj: T): T {
		console.log(`markEntityToUpdate: ${obj.getDisplayName()} ${obj.getModelId()}`);
		this.deleteEntity(obj);
		return obj;
	}

	public performUpdateOfEntity<T extends Model>(obj: T) {
		console.log(`performUpdateOfEntity: ${obj.getDisplayName()} ${obj.getModelId()}`);
		this.createEntity(obj);
	}

	public deleteEntity<T extends Model>(obj: T | string, objectType?: { new(): T } | string) {
		const entityTable = this.getDisplayName(typeof obj !== 'string' ? obj : objectType);
		const deleteMethodName = `delete${entityTable}`;
		// const id = typeof obj !== 'string' ? obj.id : obj;
		// console.log(`deleteEntity: ${deleteMethodName} ${id}`);
		if (!!this[deleteMethodName]) {
			this[deleteMethodName](obj);
		} else {
			// Fallback to the default add method
			this.removeEntityInTable(obj, objectType);
		}
	}

	public addEntityToMapObject(entityId: string, mapObject: MapObject<any>) {
		// console.log(`addEntityToMapObject ${entityId} ${mapObject.getType()}`);
		this.entityToMapObject[this.getMapObjectKey(entityId, mapObject.getType())] = mapObject.getId();
	}

	public removeEntityToMapObject(entityId: string, mapObjectType: string) {
		// console.log(`removeEntityToMapObject ${entityId} ${mapObjectType}`);
		delete this.entityToMapObject[this.getMapObjectKey(entityId, mapObjectType)];
	}

	public addConnectivity(connectivity: LinkFromLinkTo) {
		const fromLinkId = connectivity.linkFromId;
		const toLinkId = connectivity.linkToId;

		const fromLink = this.getEntity(fromLinkId, LinkEntity);
		const toLink = this.getEntity(toLinkId, LinkEntity);

		this.removeConnectivity(connectivity);

		fromLink.linkTos ??= [];
		fromLink.linkTos.push(connectivity);

		toLink.linkFroms ??= [];
		toLink.linkFroms.push(connectivity);
	}

	public removeConnectivity(connectivity: LinkFromLinkTo) {
		const fromLinkId = connectivity.linkFromId;
		const toLinkId = connectivity.linkToId;

		const fromLink = this.getEntity(fromLinkId, LinkEntity);
		const toLink = this.getEntity(toLinkId, LinkEntity);

		fromLink.linkTos = fromLink.linkTos.filter(x => x.linkToId !== toLinkId);
		toLink.linkFroms = toLink.linkFroms.filter(x => x.linkFromId !== fromLinkId);
	}

	public addPath(link: LinkEntity) {
		this.addLink(link);
	}

	public addLink(link: LinkEntity) {
		// console.log(`addLink`);
		// Use this to prevent recursion
		this.addEntityInTable(link);
		this.linkByIdNumber[link.linkId] = link;

		link.signalSetss.forEach(signal => {
			this.createEntity(signal);
			this.signalSetByLinkId[link.id] = signal;
		});

		link.isDefaultSpeed = link.isDefaultSpeed ?? true;

		link.sublinkss.forEach(sublink => {
			this.createEntity(sublink);

			if (!sublink.previousSublinkId) {
				this.startSublinkByLinkId[link.id] = sublink;
			} else {
				this.nextSublinkRef[sublink.previousSublinkId] = sublink.id;
			}

			sublink.nodess.forEach(node => {
				this.createEntity(node);

				// If constantSpeed is 0, "if (!!link.constantSpeed)" statement won't be hit in LinkProperties
				// and then map.getTracker().commitAction() won't be hit.
				// Also, "Ignore the start and end node if they have a special task (parking, reverse point or crusher dumping) because their speed will be 0"
				// refer to ACs in HITMAT-886
				if (!node.isSpecialTask()) {
					link.constantSpeed = node.speed;
				}

				if (!node.previousNodeId) {
					// Start of sublink
					this.startNodeBySublinkId[sublink.id] = node;

					if (!sublink.previousSublinkId) {
						// Start of link
						this.startNodeByLinkId[link.id] = node;
					}
				} else {
					this.nextNodeRef[node.previousNodeId] = node.id;
				}
			});
		});
	}

	// only to be used in undo/redo. ideally, this should be dealt with when the associated entity is updated
	public cleanLinkLookups(obj: LinkEntity | string) {
		const link = obj instanceof LinkEntity ? obj : this.getEntity(obj, LinkEntity);
		link.sublinkss.forEach(sublink => {
			if (!!this.nextSublinkRef[sublink.id]) {
				console.log(`cleanLinkLookups: removed nextSublinkRef for sublink ${sublink.id}`);
				delete this.nextSublinkRef[sublink.id];
			}
			sublink.nodess.forEach(node => {
				if (!!this.nextNodeRef[node.id]) {
					console.log(`cleanLinkLookups: removed nextNodeRef for sublink ${node.id}`);
					delete this.nextNodeRef[node.id];
				}
			});
		})
	}

	public deleteLink(obj: LinkEntity | string) {
		const link = obj instanceof LinkEntity ? obj : this.getEntity(obj, LinkEntity);

		link.signalSetss.forEach(signal => {
			delete this.signalSetByLinkId[link.id];
		});

		link.sublinkss.forEach(sublink => {
			if (!sublink.previousSublinkId) {
				delete this.startSublinkByLinkId[link.id];
			} else {
				delete this.nextSublinkRef[sublink.previousSublinkId];
			}

			// ensure all next sublink refs are deleted
			delete this.nextSublinkRef[sublink.id];

			sublink.nodess.forEach(node => {
				link.constantSpeed = node.speed;

				if (!node.previousNodeId) {
					// Start of sublink
					delete this.startNodeBySublinkId[sublink.id];

					if (!sublink.previousSublinkId) {
						// Start of link
						delete this.startNodeByLinkId[link.id];
					}
				} else {
					delete this.nextNodeRef[node.previousNodeId];

					// ensure all next node refs are deleted
					delete this.nextNodeRef[node.id];
				}

				this.removeEntityInTable(node);
			});

			this.removeEntityInTable(sublink);
		});

		this.removeEntityInTable(link);
		delete this.linkByIdNumber[link.linkId];
	}

	public addNextSublink(previousSublink: string, nextSublink?: string) {
		if (!nextSublink) {
			this.removeNextSublink(previousSublink);
			return;
		}
		this.nextSublinkRef[previousSublink] = nextSublink;
	}

	public removeNextSublink(previousSublinkId: string) {
		delete this.nextSublinkRef[previousSublinkId];
	}

	public addNextNode(previousNode: string, nextNode: string) {
		this.nextNodeRef[previousNode] = nextNode;
	}

	public removeNextNode(previousNode: string) {
		delete this.nextNodeRef[previousNode];
	}

	public addArea(area: AreaEntity) {
		this.addEntityInTable(area);

		this.areaByName[area.areaName] = area;
		this.baysByAreaId[area.getModelId()] = this.baysByAreaId[area.getModelId()] ?? [];

		// Add the entry and exit nodes for this area if it is an autonomous area
		if (area.areaType === 'AREAAUTONOMOUS') {
			const {entryNodeIds, exitNodeIds} = this.getEntryAndExitNodeIds();
			const exitNodeId = area.exitNodeId;
			const entryNodeId = area.entranceNodeId;

			if (!!exitNodeId) {
				exitNodeIds.push(exitNodeId);
			}

			if (entryNodeId) {
				entryNodeIds.push(entryNodeId);
			}
		}
	}

	public deleteArea(obj: AreaEntity | string) {
		const area = obj instanceof AreaEntity ? obj : this.getEntity(obj, AreaEntity);

		delete this.areaByName[area.areaName];
		delete this.baysByAreaId[area.getModelId()];

		this.removeEntityInTable(obj, AreaEntity);
	}

	public addBay(bay: BayEntity) {
		this.addEntityInTable(bay);

		const bayAreaId = bay.areaId ?? 'unknown';
		const baysList = this.baysByAreaId[bayAreaId];

		// If bays were undefined bays, remove it from 'unknown'
		if (!!this.baysByAreaId['unknown'] && bayAreaId !== 'unknown') {
			this.baysByAreaId['unknown'] = this.baysByAreaId['unknown'].filter(x => x !== bay.id);
		}

		if (!!baysList) {
			const bayExist = this.baysByAreaId[bayAreaId].findIndex(id => id === bay.getModelId());
			if (bayExist === -1) {
				this.baysByAreaId[bayAreaId].push(bay.getModelId());
			}
		} else {
			this.baysByAreaId[bayAreaId] = [bay.getModelId()];
		}
	}

	public deleteBay(obj: BayEntity | string) {
		const bay = obj instanceof BayEntity ? obj : this.getEntity(obj, BayEntity);

		const bayAreaId = bay.areaId ?? 'unknown';
		const baysList = this.baysByAreaId[bayAreaId] ?? [];
		this.baysByAreaId[bayAreaId] = baysList.filter(x => x !== bay.id);

		this.removeEntityInTable(bay);
	}

	public deleteNode(obj: NodeEntity | string) {
		const node = obj instanceof NodeEntity ? obj : this.getEntity(obj, NodeEntity);
		const sublink = node.getSublink();
		const link = node.getLink();

		const nextNode = node.getNextNode();
		const previousNode = node.getPreviousNode();

		if (!!previousNode && !!nextNode) {
			this.nextNodeRef[previousNode.getModelId()] = nextNode.getModelId();
		} else if (!!previousNode && !nextNode) {
			delete this.nextNodeRef[previousNode.getModelId()];
		}
		delete this.nextNodeRef[node.getModelId()];

		if (!!link && link.firstNode()?.getModelId() === node.getModelId()) {
			if (!!nextNode) {
				this.startNodeByLinkId[link.getModelId()] = nextNode;
			} else {
				delete this.startNodeByLinkId[link.getModelId()];
			}
		}

		if (!!sublink && sublink.getFirstNode()?.getModelId() === node.getModelId()) {
			if (!!nextNode) {
				this.startNodeBySublinkId[sublink.getModelId()] = nextNode;
			} else {
				delete this.startNodeBySublinkId[sublink.getModelId()];
			}
		}

		this.removeEntityInTable(node);
	}

	public deleteSublink(obj: SublinkEntity | string) {
		const sublink = obj instanceof SublinkEntity ? obj : this.getEntity(obj, SublinkEntity);
		const link = sublink.getLink();
		const nextSublink = sublink.getNextSublink();
		const previousSublink = sublink.getPreviousSublink();

		if (!!nextSublink && !!previousSublink) {
			this.nextSublinkRef[previousSublink.getModelId()] = nextSublink.getModelId();
		} else if (!!previousSublink && !nextSublink) {
			delete this.nextSublinkRef[previousSublink.getModelId()];
		}
		delete this.nextSublinkRef[sublink.getModelId()];

		if (!!link && link.firstSublink()?.getModelId() === sublink.getModelId()) {
			if (!!nextSublink) {
				this.startSublinkByLinkId[link.getModelId()] = nextSublink;
			} else {
				delete this.startSublinkByLinkId[link.getModelId()];
			}
		}

		delete this.startNodeBySublinkId[sublink.getModelId()];

		this.removeEntityInTable(sublink);
	}

	/**
	 * Search methods
	 */

	/**
	 * Generic search for the entity type
	 *
	 * @param id
	 * @param objectType
	 */
	public getEntity = <T extends Model>(id: T | string, objectType?: { new(): T } | string): T => {
		const entityTable = this.getModelName(typeof id !== 'string' ? id : objectType);
		const entityId = typeof id !== 'string' ? id.getModelId() : id;
		return this.entities[entityTable][entityId] as T;
	};

	public getEntityUnknown = (id: string): unknown => {
		return Object.values(this.entities)
			.map(x => x[id])
			.find(x => x !== undefined);
	};

	public getAllEntities = <T extends Model>(objectType: { new(): T } | string): T[] => {
		const entityTable = typeof objectType === 'string' ? objectType : getModelName(objectType);
		return Object.values(this.entities[entityTable]) as T[];
	};

	public getDrivingAreas(): DrivingAreaEntity[] {
		return this.getImportVersion().drivingAreass;
	}

	public getMapObjectId(entityId: string, mapObjectType: string): string {
		return this.entityToMapObject[this.getMapObjectKey(entityId, mapObjectType)];
	}

	public getMapObjectIdByEntity<T extends Model>(model: T, mapObjectType: string): string {
		return this.getMapObjectId(model.getModelId(), mapObjectType);
	}

	public getMapObjectByEntity<T extends Model>(model: T, mapObjectType: string): MapObject<unknown> | undefined {
		return store.renderer.getObjectById(this.getMapObjectIdByEntity(model, mapObjectType));
	}

	public getMapObjectByEntityId(entityId: string, mapObjectType: string): MapObject<unknown> | undefined {
		return store.renderer.getObjectById(this.getMapObjectId(entityId, mapObjectType));
	}

	public getMapObjectById(objId: string): MapObject<unknown> | undefined {
		// Better to use this method than getObjectById 
		return store.renderer.getObjectById(objId);
	}

	public getFirstNodeForLink(id: string): NodeEntity | undefined {
		return this.startNodeByLinkId[id];
	}

	public getFirstNodeForSublink(id: string): NodeEntity | undefined {
		return this.startNodeBySublinkId[id];
	}

	public getFirstSublinkForLink(id: string): SublinkEntity | undefined {
		return this.startSublinkByLinkId[id];
	}

	public getSignalSetByLinkId(id: string): SignalSetEntity | undefined {
		return this.signalSetByLinkId[id];
	}

	public setSignalSetByLinkId(signal: SignalSetEntity) {
		if (signal.linkId) {
			this.signalSetByLinkId[signal.linkId!] = signal;
		}
	}

	public getNextSublinkById(id: string): SublinkEntity | undefined {
		const nextId = this.nextSublinkRef[id];
		if (!nextId) {
			return undefined;
		}

		return this.getEntity(nextId, SublinkEntity);
	}

	public getNextNodeById(id: string): NodeEntity | undefined {
		const nextId = this.nextNodeRef[id];
		if (!nextId) {
			return undefined;
		}

		return this.getEntity(nextId, NodeEntity);
	}

	public getLinkByIdNumber(idNumber: number): LinkEntity | undefined {
		return this.linkByIdNumber[idNumber];
	}

	public getBaysByAreaId(id: string): BayEntity[] | undefined {
		return this.baysByAreaId[id]?.map(bayId => this.getEntity(bayId, BayEntity)).filter(x => !!x);
	}

	public getAreaByName(name: string): AreaEntity | undefined {
		return this.areaByName[name];
	}
	
	public getLinkById(id: string) {
		return this.getEntity(id, LinkEntity);
	}

	public getSublinkById(id: string) {
		return this.getEntity(id, SublinkEntity);
	}

	public getMapErrors() {
		return this.mapErrors;
	}

	public getOldMapErrorsServerSide() {
		return this.oldMapErrorsServerSide;
	}

	public setNewMapErrorsServerSideToOldOne() {
		this.oldMapErrorsServerSide = this.mapErrorsServerSide;
	}

	public getMapErrorsServerSide() {
		return this.mapErrorsServerSide;
	}

	public setMapErrorsServerSide(errors: ErrorWarningStore) {
		this.mapErrorsServerSide = errors;
	}

	public getMapWarnings() {
		return this.mapWarnings;
	}

	public getOldMapWarningsServerSide() {
		return this.oldMapWarningsServerSide;
	}

	public setNewMapWarningsServerSideToOldOne() {
		this.oldMapWarningsServerSide = this.mapWarningsServerSide;
	}

	public getMapWarningsServerSide() {
		return this.mapWarningsServerSide;
	}

	public setMapWarningsServerSide(warnings: ErrorWarningStore) {
		this.mapWarningsServerSide = warnings;
	}

	public getTempMapWarningsClientSide() {
		return this.tempMapWarnings;
	}

	public setTempMapWarningsClientSide(warnings: TempErrorWarningStore) {
		this.tempMapWarnings = warnings;
	}

	public clearTempMapWarnings() {
		this.tempMapWarnings = {};
	}

	/**
	 * Id methods
	 */
	public getNextAvailableLinkId(amount?: number): number[] {
		const result = this.getNextAvailableIdForEntity(
			LinkEntity,
			'linkId',
			this.linkIdSearchStart,
			this.mapParamsEntity.staticLinkIdMax,
			amount,
		);

		if (result.length > 0) {
			this.linkIdSearchStart = result[result.length - 1] + 1;
		}

		return result;
	}

	public getNextAvailableSublinkId(amount?: number): number[] {
		const result = this.getNextAvailableIdForEntity(
			SublinkEntity,
			'sublinkId',
			this.sublinkIdSearchStart,
			SUBLINK_ID_MAX,
			amount,
		);

		if (result.length > 0) {
			this.sublinkIdSearchStart = result[result.length - 1] + 1;
		}

		return result;
	}

	public getNextAvailableNodeId(amount?: number): number[] {
		const result = this.getNextAvailableIdForEntity(
			NodeEntity,
			'nodeId',
			this.nodeIdSearchStart,
			this.mapParamsEntity.nodeIdMax,
			amount,
		);

		if (result.length > 0) {
			this.nodeIdSearchStart = result[result.length - 1] + 1;
		}

		return result;
	}
    
    public hasReachedMaxSublinks () {
        return this.mapParamsEntity.staticPathSublinkMax <= this.getAllEntities('SublinkEntity').length;
    }

    public hasReachedMaxLinks () {
        return this.mapParamsEntity.staticPathLinkMax <= this.getAllEntities('LinkEntity').length;
    }

	/* *****************
	 * Map Error Updates
	 ***************** */

	public getMapErrorCount(): number {
		this.objectErrorCount = 0;
		Object.entries(this.mapErrors)
			.map(([entityType, ids]) => {
				Object.entries(ids as Object).map(([id, errors]) => {
					this.objectErrorCount += this.mapErrors[entityType][id].length;
				});
			});
		return this.objectErrorCount;
	}

	public getObjectErrorCount<T extends MapObjectErrorType>(objectType: { new(): T }) {
		const errorsForType = this.mapErrors[this.getModelName(objectType)];

		if (!!errorsForType) {
			return Object.values(errorsForType).reduce((acc, errors) => acc + errors.length, 0);
		}

		return 0;
	}

	/**
	 * Add one error to mapObjectErrorss of an entity and update the error table.
	 */
	public addObjectError<T extends MapObjectErrorType>(id: string, objectType: { new(): T }, error: string) {
		const entity = this.getEntity(id, objectType);
		if (!!entity && (entity.hasError(error) || entity.addError(error)) ) {
			this.addErrorInErrorTable(entity, error);
		}
	}

	/**
	 * Remove one error from mapObjectErrorss of an entity and update the error table.
	 */
	public removeObjectError<T extends MapObjectErrorType>(id: string, objectType: { new(): T }, error: string) {
		const entity = this.getEntity(id, objectType);
		if (!!entity && entity.removeError(error)) {
			const errors = entity.mapObjectErrorss.map(err => err.errorMessage);
			this.reassignErrorsInErrorTable(entity, errors);
		}
	}

	/**
	 * Add multiple errors to mapObjectErrorss of an entity and update the error table.
	 */
	public addNewErrorsForObject<T extends MapObjectErrorType>(id: string, objectType: { new(): T }, errors: string[]): boolean {
		const entity = this.getEntity(id, objectType);
		if (!!entity) {
			entity.addErrors(errors);
			const newErrors = entity.mapObjectErrorss.map(err => err.errorMessage);
			this.reassignErrorsInErrorTable(entity, newErrors);
			return true;
		}
		return false;
	}

	/**
	 * Reset and add multiple new errors to mapObjectErrorss of an entity.
	 */
	public resetAndAddNewErrors<T extends MapObjectErrorType>(id: string, objectType: { new(): T }, errors: string[]): boolean {
		const entity = this.getEntity(id, objectType);
		if (!!entity) {
			entity.resetAndAddNewErrors(errors);
			const newErrors = entity.mapObjectErrorss.map(err => err.errorMessage);
			this.reassignErrorsInErrorTable(entity, newErrors);
			return true;
		}
		return false;
	}

	/**
	 * Add one error to an entity in the error table.
	 */
	public addErrorInErrorTable = <T extends Model>(obj: T, errorMsg: string) => {
		let errors = this.mapErrors[this.getModelName(obj)][obj.getModelId()];
		if (!errors) {
			this.mapErrors[this.getModelName(obj)][obj.getModelId()] = [errorMsg];
		} else if (!!errors && errors.indexOf(errorMsg) < 0) {
			errors.push(errorMsg);
			this.mapErrors[this.getModelName(obj)][obj.getModelId()] = errors;
		}
	};

	/**
	 * Re-assign errors of an entity in the error table.
	 */
	public reassignErrorsInErrorTable = <T extends Model>(obj: T, errorMsgs: string[]) => {
		this.mapErrors[this.getModelName(obj)][obj.getModelId()] = errorMsgs;
	};

	/**
	 * Remove an entity in the error table.
	 */
	public removeEntityInErrorTable = <T extends Model>(obj: T | string, objectType?: { new(): T } | string) => {
		const objIsObject = typeof obj !== 'string';
		const objectTypeExists = !!objectType;

		if (!objIsObject && !objectTypeExists) {
			throw Error('Unable to find object');
		}

		const entityTable = this.getModelName(objIsObject ? obj : objectType);

		const entityId = objIsObject ? obj.getModelId() : obj;
		delete this.mapErrors[entityTable][entityId];
	};

	public resetMapErrors () {
		Object.entries(this.mapErrors).map(([entityType, object]) => {
			this.mapErrors[entityType] = {};
		});
	}

	/* *****************
	 * Map Warning Updates
	 ***************** */

	public getMapWarningCount(): number {
		this.objectWarningCount = 0;
		Object.entries(this.mapWarnings)
			.map(([entityType, ids]) => {
				Object.entries(ids as Object).map(([id, warnings]) => {
					this.objectWarningCount += this.mapWarnings[entityType][id].length;
				});
			});
		return this.objectWarningCount;
	}

	/**
	 * Add one warning to mapObjectWarningss of an entity and update the warning table.
	 */
	public addObjectWarning<T extends MapObjectErrorType>(id: string, objectType: { new(): T }, warning: string) {
		const entity = this.getEntity(id, objectType);
		if (!!entity && (entity.hasWarning(warning) || entity.addWarning(warning)) ) {
			this.addWarningInWarningTable(entity, warning);
		}
	}

	/**
	 * Remove one warning from mapObjectWarningss of an entity and update the warning table.
	 */
	public removeObjectWarning<T extends MapObjectErrorType>(id: string, objectType: { new(): T }, warning: string) {
		const entity = this.getEntity(id, objectType);
		if (!!entity && entity.removeWarning(warning)) {
			const warnings = entity.mapObjectWarningss.map(warning => warning.warningMessage);
			this.reassignWarningsInWarningTable(entity, warnings);
		}
	}

	/**
	 * Add multiple warnings to mapObjectWarningss of an entity and update the warning table.
	 */
	public addNewWarningsForObject<T extends MapObjectErrorType>(id: string, objectType: { new(): T }, warnings: string[]): boolean {
		const entity = this.getEntity(id, objectType);
		if (!!entity) {
			entity.addWarnings(warnings);
			const newWarnings = entity.mapObjectWarningss.map(warning => warning.warningMessage);
			this.reassignWarningsInWarningTable(entity, newWarnings);
			return true;
		}
		return false;
	}

	/**
	 * Reset and add multiple new warnings to mapObjectWarningss of an entity.
	 */
	public resetAndAddNewWarnings<T extends MapObjectErrorType>(id: string, objectType: { new(): T }, warnings: string[]): boolean {
		const entity = this.getEntity(id, objectType);
		if (!!entity) {
			entity.resetAndAddNewWarnings(warnings);
			const newWarnings = entity.mapObjectWarningss.map(warning => warning.warningMessage);
			this.reassignWarningsInWarningTable(entity, newWarnings);
			return true;
		}
		return false;
	}

	/**
	 * Add one warning to an entity in the warning table.
	 */
	public addWarningInWarningTable = <T extends Model>(obj: T, warningMsg: string) => {
		let warnings = this.mapWarnings[this.getModelName(obj)][obj.getModelId()];
		if (!warnings) {
			this.mapWarnings[this.getModelName(obj)][obj.getModelId()] = [warningMsg];
		} else if (!!warnings && warnings.indexOf(warningMsg) < 0) {
			warnings.push(warningMsg);
			this.mapWarnings[this.getModelName(obj)][obj.getModelId()] = warnings;
		}
	};

	/**
	 * Re-assign warnings of an entity in the warning table.
	 */
	public reassignWarningsInWarningTable = <T extends Model>(obj: T, warningMsgs: string[]) => {
		this.mapWarnings[this.getModelName(obj)][obj.getModelId()] = warningMsgs;
	};

	/**
	 * Remove an entity in the warning table.
	 */
	public removeEntityInWarningTable = <T extends Model>(obj: T | string, objectType?: { new(): T } | string) => {
		const objIsObject = typeof obj !== 'string';
		const objectTypeExists = !!objectType;

		if (!objIsObject && !objectTypeExists) {
			throw Error('Unable to find object');
		}

		const entityTable = this.getModelName(objIsObject ? obj : objectType);

		const entityId = objIsObject ? obj.getModelId() : obj;
		delete this.mapWarnings[entityTable][entityId];
	};

	public resetMapWarnings () {
		Object.entries(this.mapWarnings).map(([entityType, object]) => {
			this.mapWarnings[entityType] = {};
		});
	}

	public getIsViewMenuShown () {
		return this.isViewMenuShown;
	}

	public setIsViewMenuShown (isViewMenuShown: IMenuShownStatus) {
		this.isViewMenuShown = { ...isViewMenuShown };
	}

	public getLinkLayersPanelShownStatus (id: string) {
		return this.linkLayersPanelShownStatus[id];
	}

	public setLinkLayersPanelShownStatus (id: string, isDisplay: boolean) {
		this.linkLayersPanelShownStatus[id] = isDisplay;
		console.log(this.linkLayersPanelShownStatus);
	}
	
	public setChildLinkLayersPanelShownStatus (objectItem: ILayersPanelItem, isDisplay: boolean) {
		if (objectItem.mapObjectType === 'area' || objectItem.mapObjectType === 'sublink') {
			return;
		}
		
		if (objectItem.mapObjectType === 'link' && !!objectItem.entityId) {
			this.setLinkLayersPanelShownStatus(objectItem.entityId, isDisplay);
		}

		objectItem.children?.forEach((child: ILayersPanelItem) => {
			this.setChildLinkLayersPanelShownStatus(child, isDisplay);
		});
	}	

	public getDrivingAreaObjByDringZoneObjId (mapObjectId: string) {
		return this.drivingAreaObjByDringZoneObjId[mapObjectId];
	}

	public setDrivingAreaObjByDringZoneObjId (mapObjectId: string, drivingArea: DrivingArea) {
		return this.drivingAreaObjByDringZoneObjId[mapObjectId] = drivingArea;
	}

	public deleteDrivingAreaObjByDringZoneObjId(mapObjectId: string) {
		delete this.drivingAreaObjByDringZoneObjId[mapObjectId];
	}

	/* *****************
	 * Driving Zone Updates
	 ***************** */

	/**
	 * Add a driving zone entry that needs updating
	 * @param data
	 */
	public addDrivingZoneUpdateEntry(data: IDrivingZoneUpdate) {
		const { sublinkId, drivingZone, nodes } = data;
		this.drivingZoneUpdate[sublinkId] = {
			drivingZone: drivingZone,
			nodes: nodes,
		};
	}

	public hasPendingDrivingZoneUpdate() {
		return Object.keys(this.drivingZoneUpdate).length > 0;
	}

	/**
	 * gets the entries that need updating on backend
	 * @returns data as IDrivingZoneUpdate for processing on server
	 */
	public getDrivingZoneUpdateEntries() {

		return Object.entries(this.drivingZoneUpdate).map(([sublinkId, data]): IDrivingZoneUpdate => (
			{
				sublinkId: sublinkId,
				nodes: data.nodes,
				drivingZone: data.drivingZone
			}
		));
	}

	public deleteDrivingZoneUpdateEntry(sublinkId: string) {
		delete this.drivingZoneUpdate[sublinkId];
	}

	private addEntityInTable = <T extends Model>(obj: T) => {
		// This may beable to be deleted if no any error saved in the database
		if (!!obj['getErrorCount']) {
			const num = (obj as unknown as MapObjectErrorType).getErrorCount();
			if (num > 0 && !!obj['getErrors']) {
				const errorMsgs = (obj as unknown as MapObjectErrorType).getErrors().map(err => err.errorMessage);
				this.reassignErrorsInErrorTable(obj, errorMsgs);
			}
			
		}

		// This may not be necessary if no any warning saved in the database
		if (!!obj['getWarningCount']) {
			const num = (obj as unknown as MapObjectErrorType).getWarningCount();
			if (num > 0 && !!obj['getWarnings']) {
				const warningMsgs = (obj as unknown as MapObjectErrorType).getWarnings().map(warning => warning.warningMessage);
				this.reassignWarningsInWarningTable(obj, warningMsgs);
			}
		}
		// console.log(`addEntityInTable ${this.getModelName(obj)} ${obj.getModelId()}`);
		this.entities[this.getModelName(obj)][obj.getModelId()] = obj;
	};

	private removeEntityInTable = <T extends Model>(obj: T | string, objectType?: { new(): T } | string) => {
		const objIsObject = typeof obj !== 'string';
		const objectTypeExists = !!objectType;

		if (!objIsObject && !objectTypeExists) {
			throw Error('Unable to find object');
		}

		// Update the error and warning tables
		this.removeEntityInErrorTable(obj, objectType);
		this.removeEntityInWarningTable(obj, objectType)

		const entityTable = this.getModelName(objIsObject ? obj : objectType);

		const entityId = objIsObject ? obj.getModelId() : obj;
		// console.log(`removeEntityInTable ${entityTable} ${entityId}`);
		delete this.entities[entityTable][entityId];
	};

	private getModelName = <T extends Model>(obj: T | { new(): T } | string | undefined) => {
		if (!obj) {
			throw Error('Can not get model name of undefined');
		}

		let modelName = '';
		if (obj instanceof Model) {
			modelName = obj.getModelName();
		} else {
			modelName = typeof obj !== 'string' ? getModelName(obj) : obj;
		}

		if (!modelName.endsWith('Entity')) {
			return `${modelName}Entity`;
		}

		return modelName;
	};

	private getDisplayName = <T extends Model>(obj: T | { new(): T } | string | undefined) => {
		if (!obj) {
			throw Error('Can not get model name of undefined');
		}

		if (obj instanceof Model) {
			return obj.getModelDisplayName();
		}

		const displayName: string = typeof obj !== 'string' ? getModelDisplayName(obj) : obj;

		if (displayName.endsWith('Entity')) {
			return displayName.replace('Entity', '');
		}

		return displayName;
	};

	private getNextAvailableIdForEntity = <T extends Model>(
		model: { new(): Model },
		attribute: string,
		startingBound: number,
		maxBound: number,
		amount?: number) => {
		const allModels = this.getAllEntities(model);
		const allModelIds = allModels.map(x => x[attribute]);

		const newIds = [];
		for (let i = 0; i < (amount ?? 1); i++) {
			const lowerBound: number = newIds.length > 0 ? newIds[newIds.length - 1] + 1 : startingBound;
			newIds.push(this.getNextAvailableId(allModelIds, lowerBound, maxBound));
		}

		return newIds;
	}

	/**
	 * Retrieves the next available id for
	 * link/sublink/node
	 * @param lowerBound
	 * @param existingIds
	 * @param upperBound
	 */
	private getNextAvailableId = (existingIds: number[], lowerBound: number, upperBound: number): number => {
		let tempIdCounter = lowerBound;
		let idFound: boolean = false;
		let looped = 0;

		while (!idFound && looped !== 2) {
			if (existingIds.every(id => id !== tempIdCounter)) {
				idFound = true;
				break;
			}

			tempIdCounter++;
			if (tempIdCounter > upperBound) {
				tempIdCounter = 1;
				looped++;
			}
		}

		if (!idFound) {
			throw Error('Unable to find available ID');
		}
		return tempIdCounter;
	};

	private getMapObjectKey = (entityId: string, mapType: string) => `${mapType}_${entityId}`;

	// Used for debugging
	// eg. from console document.mapLookup.printLink(279)
	public printLink(id: number | string | LinkEntity, useLookup: boolean = false) {
		let link: LinkEntity | undefined;
		if (id instanceof LinkEntity) {
			link = id;
		} else {
			if (typeof id === 'number') {
				link = this.getLinkByIdNumber(id);
			} else {
				link = this.getLinkById(id);
			}
		}
		if (!!link) {
			console.log(this.getLinkAsString(link, useLookup));
		} else {
			console.log(`Error. Link ${id} not found in lookup`);
		}
	}

	// used for testing/debugging
	public printSublinks(linkEntity: LinkEntity) {
		let str = '';
		linkEntity.sublinkss.forEach(sl => (str +=this.getSublinkAsString(sl)));
		console.log(str);
	}

	// used for testing/debugging
	public printNodes(sublinkEntity: SublinkEntity) {
		let str = '';
		sublinkEntity.nodess.forEach(n => (str +=this.getNodeAsString(n)));
		console.log(str);
	}

	// used for testing/debugging
	public getLinkAsString(linkEntity: LinkEntity, useLookup: boolean = false) {
		let str = `*** Print link id ${linkEntity.linkId} ${linkEntity.getModelId()} isdefaultspeed: ${linkEntity.isDefaultSpeed} constantSpeed: ${linkEntity.constantSpeed} ***\n`;
		const sublinks = useLookup ? linkEntity.getSublinks() : linkEntity.sublinkss; 
		sublinks.forEach(sl => {
			str += this.getSublinkAsString(sl);
			const nodes = useLookup ? sl.getNodes() : sl.nodess;
			nodes.forEach(n => {
				str += `\t${this.getNodeAsString(n)}`;
			});
		});
		str += this.getLinkFromLinkToStrings(linkEntity);
		str += '********End********\n';
		return str;
	}

	// used for debugging
	public getLinkInfo(link: LinkEntity) {
		return  `${link.id} (${link.linkId})`;
	}

	// used for testing/debugging
	public printLinkConnectivity(link: LinkEntity) {
		let s = `Print Connectivity for ${this.getLinkInfo(link)}\n`;
		s += this.getLinkFromLinkToStrings(link);
		console.log(s);
	}

	public getLinkFromLinkToStrings(link: LinkEntity) {
		let str = '';
		str += "LinkFroms\n";
		str += this.getConnectivityString(link.linkFroms);
		str += "LinkTos\n";
		str += this.getConnectivityString(link.linkTos);
		return str;
	}

	// used for testing/debugging
	public getConnectivityString(lflt: LinkFromLinkTo[]) {
		let str = '';
		lflt.forEach(l => {
			const linkFromIdNumber = this.getLinkById(l.linkFromId)?.linkId;
			const linkToIdNumber = this.getLinkById(l.linkToId)?.linkId;
			str += `linkFromId: ${l.linkFromId} (${linkFromIdNumber}) linkToId: ${l.linkToId} (${linkToIdNumber})\n`;
		});
		return str;
	}

	// used for testing/debugging
	public getSublinkAsString(sl: SublinkEntity) {
		return `${sl.sublinkId} id ${sl.id} {prev ${sl.previousSublinkId}} next {${!!sl.nextSublink ? sl.nextSublink.sublinkId : String(sl.nextSublink)}}\n`
	}

	// used for testing/debugging
	public getNodeAsString(n: NodeEntity) {
		const nextNode = !!n.nextNode ? `${n.nextNode.nodeId} (${n.nextNode.id})` : String(n.nextNode);
		let slIdByRef = !!n.sublink ? String(n.sublink.id === n.sublinkId) : 'none';
		return `${n.nodeId} id ${n.task} ${n.id} {prev ${n.previousNodeId}} {next ${nextNode}} (sl: ${n.sublinkId} slRef: ${slIdByRef})\n`;
	}

	// used for testing/debugging
	public countNodes(linkEntity: LinkEntity) {
		let totalNodes = 0;
		linkEntity.sublinkss.forEach(sl => {
			sl.nodess.forEach(n => {
				totalNodes++;
			});
		});
		return totalNodes;
	}
}
