import { LinkEntity, LinkFromLinkTo } from "Models/Entities";
import MapController from "../MapController";
import MapEventHandler from "../MapEventHandler";
import MapStore from "../MapStore";
import PathToolHelper from './PathToolHelper';
import { ActionGroupType } from "Views/MapComponents/UndoRedo/UndoRedoTracker";
import { getAffectedLinks, getCalDZDistance, travelPathBackward, travelPathForward } from "../Helpers/DrivingZone";

// This module contains helpers related to manipulating connectivity
// Important note: Any methods which do not recalculate drivingzones should
// be marked as such (e.g. add WithoutRecalc to method name)

export default class LinkConnectivityEditHelper {
	/**
	 * Re-adds a connectivity entity (used in undo/redo of connectivity)
	 * @param entity 
	 * @param controller 
	 */
	public static reAddConnectivityEntity(entity: LinkFromLinkTo, controller: MapController, opType?: ActionGroupType) {
		const endLink = controller.getMapLookup().getEntity(entity.linkFromId, LinkEntity);
		const startLink = controller.getMapLookup().getEntity(entity.linkToId, LinkEntity);

		if (!endLink) {
			console.log(`reAddConnectivityEntity: linkFromId ${entity.linkFromId} not found`);
			return;
		}

		if (!startLink) {
			console.log(`reAddConnectivityEntity: linkToId ${entity.linkToId} not found`);
			return;
		}

		// step 1 - create a connectivity
		if (!this.isConnectivityAlreadyAdded(startLink.linkFroms, entity.linkFromId, entity.linkToId)) {
			startLink?.linkFroms.push(entity);
		}
		if (!this.isConnectivityAlreadyAdded(endLink.linkTos, entity.linkFromId, entity.linkToId)) {
			endLink.linkTos.push(entity);
		}
		const mapParams = controller.getMapLookup().getMapParameters();
		const affectedLinks = getAffectedLinks(endLink, startLink, mapParams);

		// block calculateDrivingZone for undo/redo break/join link
		// because linkEntity has not been created in the database yet
		// and use PropertyUpdateAction to update sublink driving zone instead of recalculating it
		if (affectedLinks.length > 0 && (!opType || (opType !== 'break_link' && opType !== 'join_link'))) {
			console.log(`reAddConnectivityEntity: ${affectedLinks.length}`);
			PathToolHelper.calculateDrivingZone(controller.getMapLookup(), controller.getImportVersion().id, affectedLinks, undefined, 'LCEH.reAddConnectivityEntity');
		}

		controller.getEventHandler().setActiveTool('selector');
	}

	public static isConnectivityAlreadyAdded(connectivities: LinkFromLinkTo[], linkFromId: string, linkToId: string) {
		return connectivities.some(c => (c.linkFromId === linkFromId) && (c.linkToId === linkToId));
	}

	/**
	 * Takes a link with connectivities and adds connectivity information
	 * to the associated links. Useful for Undo/Redo.
	 * @param link 
	 * @param lookup 
	 */
	public static addConnectivityToAssociatedLinks = async (link: LinkEntity, lookup: MapStore, opType?: ActionGroupType) => {
		// Cycle through the linktos and linkfroms of the link and add connectiviy to associated link
		// const toAdd: LinkFromLinkTo[] = [];
		const affectedLinks: LinkEntity[] = [link];
		const mapParams = lookup.getMapParameters();

		// The comments below refer to this structure: LinksPrev -> LinkCurrent -> LinksNext
		// Where LinksPrev and LinksNext can both contain more than one link

		// Handles adding connectivity information to LinksPrev
		link.linkFroms.forEach(lf => {
			const { linkFromId } = lf;
			const { linkToId } = lf;
			const linkFrom = lookup.getEntity(linkFromId, LinkEntity);
			if (!!linkFrom) {
				travelPathBackward(linkFrom, affectedLinks, getCalDZDistance(mapParams));
				travelPathForward(linkFrom, affectedLinks, linkFrom.getDistance() + getCalDZDistance(mapParams));
				if (!this.isConnectivityAlreadyAdded(linkFrom.linkTos, linkFromId, linkToId)) {
					linkFrom.linkTos.push(lf);
				}
			} else {
				console.log(`addConnectivityToAssociatedLinks: linkFromId ${linkFromId} not found`);
			}
		});

		// Handles adding connectivity information to LinkNext
		link.linkTos.forEach(lt => {
			const { linkFromId } = lt; 
			const { linkToId } = lt;
			const linkTo =  lookup.getEntity(linkToId, LinkEntity);
			if (!!linkTo) {
				travelPathBackward(linkTo, affectedLinks, linkTo.getDistance() + getCalDZDistance(mapParams));
				travelPathForward(linkTo, affectedLinks, getCalDZDistance(mapParams));
				if ((!this.isConnectivityAlreadyAdded(linkTo.linkFroms, linkFromId, linkToId))) {
					linkTo.linkFroms.push(lt);
				}
			} else {
				console.log(`addConnectivityToAssociatedLinks: linkToId ${linkToId} not found`);
			}
		});
		// block calculateDrivingZone for undo/redo break/join link
		// because linkEntity has not been created in the database yet
		// and use PropertyUpdateAction to update sublink driving zone instead of recalculating it
		if (affectedLinks.length > 0 && (!opType || (opType !== 'break_link' && opType !== 'join_link'))) {
			console.log(`addConnectivityToAssociatedLinks: Recalculating driving zone of ${affectedLinks.length} connectivity objects`);
			await PathToolHelper
				.calculateDrivingZone(lookup, lookup.getImportVersion().id, affectedLinks, undefined, 'LCEH.addConnectivityToAssociatedLinks');
		}
		console.log(`addConnectivityToAssociatedLinks: Finished`);
	}	

	/**
	 * Break all the connectivity associated with
	 * the link to be deleted. i.e. Tos and Froms
	 * @param linkToDelete
	 */
	 public static removeConnectivityFromAssociatedLinks = async (linkToDelete: LinkEntity, lookup: MapStore) => {
		// const linkInfo = lookup.getLinkInfo(linkToDelete);
		// console.log(`removeConnectivityFromAssociatedLinks: Removing connectivities associated with link ${linkInfo}`);
		const linkEntity = linkToDelete;
		const linkFroms = linkEntity.previousLinks();
		const linkTos = linkEntity.nextLinks();
		const affectedLinks: LinkEntity[] = [];
		const mapParams = lookup.getMapParameters();
		/**
		 * Handle link froms
		 */
		linkFroms?.forEach(link => {
			travelPathBackward(link, affectedLinks, getCalDZDistance(mapParams));
			travelPathForward(link, affectedLinks, link.getDistance() + getCalDZDistance(mapParams));
			link.linkTos = link.linkTos.filter(item => {
				return item.linkToId !== linkEntity.getModelId();
			});
		});
		/**
		 * Handle link tos
		 */
		linkTos?.forEach(link => {
			travelPathBackward(link, affectedLinks, link.getDistance() + getCalDZDistance(mapParams));
			travelPathForward(link, affectedLinks, getCalDZDistance(mapParams));
			link.linkFroms = link.linkFroms.filter(item => {
				return item.linkFromId !== linkEntity.getModelId();
			});
		});

		console.log(`removeConnectivityFromAssociatedLinks: Finished`);
	}

	// this method is to deal with special case when a link is edited. usually linkId is used, but in this case the
	// entity id must be used.
	public static removeConnectivityFromAssociatedLinksUsingUUID = async (linkToDelete: LinkEntity, lookup: MapStore, isRecalcDZ: boolean = true) => {
		const link = linkToDelete;
		const changedLinks: LinkEntity[] = [];
		const mapParams = lookup.getMapParameters();
		// console.log(`removeConnectivityFromAssociatedLinksUUID: Removing connectivities associated with link ${lookup.getLinkInfo(link)})`);

		// The comments below refer to this structure: LinksPrev -> LinkCurrent -> LinksNext
		// Where LinksPrev and LinksNext can both contain more than one link

		// Handles adding connectivity information to LinksPrev
		link.linkFroms.forEach(lf => {
			const { linkFromId } = lf;
			const { linkToId } = lf;
			const linkFrom = lookup.getEntity(linkFromId, LinkEntity);
			if (!!linkFrom) {
				travelPathBackward(linkFrom, changedLinks, getCalDZDistance(mapParams));
				travelPathForward(linkFrom, changedLinks, linkFrom.getDistance() + getCalDZDistance(mapParams));
				linkFrom.linkTos = linkFrom.linkTos.filter(c => c.linkToId !== link.id);
			}
		});

		// Handles adding connectivity information to LinkNext
		link.linkTos.forEach(lt => {
			const { linkFromId } = lt; 
			const { linkToId } = lt;
			const linkTo =  lookup.getEntity(linkToId, LinkEntity);
			if (!!linkTo) {
				travelPathBackward(linkTo, changedLinks, linkTo.getDistance() + getCalDZDistance(mapParams));
				travelPathForward(linkTo, changedLinks, getCalDZDistance(mapParams));
				linkTo.linkFroms = linkTo.linkFroms.filter(c => c.linkFromId !== link.id);
			}
		});

		if (changedLinks.length > 0) {
			if (isRecalcDZ) {
				console.log(`removeConnectivityFromAssociatedLinksUUID: Updating ${changedLinks.length} links`);
				await PathToolHelper
					.calculateDrivingZone(lookup, lookup.getImportVersion().id, changedLinks, undefined, 'LCEH.removeConnectivityFromAssociatedLinksUsingUUID');
			} else {
				console.log(`removeConnectivityFromAssociatedLinksUsingUUID: NOT recalculating connectivity`);
			}
		}
	}

	/**
	 * General method for creating connectivity between two links in form fromLink -> toLink
	 * @param eventHandler 
	 * @param toLink 
	 * @param fromLink 
	 * @param useLinkEntity 
	 * @param dontReCalculateDrivingZone 
	 * @returns 
	 */
	public static createConnectivity = async (
			eventHandler: MapEventHandler,
			toLink: LinkEntity,
			fromLink: LinkEntity,
			useLinkEntity?: boolean,
		): Promise<LinkFromLinkTo | undefined> => {

		const newLinkFrom = this.createConnectivityNoRecalc(eventHandler, toLink, fromLink);
		// debugger;
		if (!newLinkFrom) {
			console.log('createConnectivity: newLinkFrom undefined from createConnectivityNoRecalc. Not continuing.');
			return undefined;
		}

		if (toLink && fromLink) {
			console.log('createConnectivity: Calc driving zones');
			const importVersionId = eventHandler.getController().getImportVersion().id;
			const lookup = eventHandler.getLookup();
			if (!!useLinkEntity) {
				const mapParams = eventHandler.getController().getMapLookup().getMapParameters();
				const affectedLinks = getAffectedLinks(fromLink, toLink, mapParams);
				await PathToolHelper.calculateDrivingZone(lookup, importVersionId, affectedLinks, undefined, 'LCEH.createConnectivity (to/from');
			} else {
				await PathToolHelper.calculateDrivingZone(lookup, importVersionId, undefined, newLinkFrom, 'LCEH.createConnectivity (connectivity param)');
			}
			console.log('createConnectivity: Done');
		}
		return newLinkFrom;
	}

	/**
	 * General method for creating connectivity between two links in form fromLink -> toLink
	 * @param eventHandler 
	 * @param toLink 
	 * @param fromLink 
	 * @param isUpdateLinks
	 * @returns 
	 */
	 public static createConnectivityNoRecalc = (
		eventHandler: MapEventHandler,
		toLink: LinkEntity,
		fromLink: LinkEntity,
		isUpdateLinks: boolean = true
	): LinkFromLinkTo | undefined => {

	const lookup = eventHandler.getController().getMapLookup();

	const hasConnection = toLink.linkFroms.some(lflt => lflt.linkFromId === fromLink.id);

	if (hasConnection) {
		console.warn('Connectivity failed: links already have connectivity');
		return undefined;
	}

	// fromLink (linkFrom) -> toLink (linkTo)

	// Add connectivity
	const newLinkFrom = new LinkFromLinkTo({
		linkFrom: fromLink, linkFromId: fromLink.id, linkTo: toLink, linkToId: toLink.id,
	});
	newLinkFrom.id = newLinkFrom._clientId;
	if (isUpdateLinks) {
		toLink.linkFroms.push(newLinkFrom);
		fromLink.linkTos.push(newLinkFrom);
	}
	// const debugString = `createConnectivity: Adding\nfromLink ${lookup.getLinkInfo(fromLink)}\ntoLink ${lookup.getLinkInfo(toLink)}\nCOnnectivity:\n${lookup.getConnectivityString([newLinkFrom])}`;
	// console.log(debugString);
	return newLinkFrom;
}

	// public static addConnectivityWithout

	// Currently used to reassign connectivity when link is broken
	// eslint-disable-next-line max-len
	public static reassignEndwaypointConnectivityNoRecalc(eventHandler: MapEventHandler, originalLink: LinkEntity, newLink: LinkEntity) {
		newLink.linkTos = originalLink.linkTos;
		originalLink.linkTos = [];
		const lookup = eventHandler.getController().getMapLookup();
		newLink.linkTos.forEach(lflt => {
			lflt.linkFrom = newLink;
			lflt.linkFromId = newLink.id;
			const { linkToId } = lflt;
			const linkTo = eventHandler.getLookup().getEntity(linkToId, LinkEntity);
			const connectivity = linkTo?.linkFroms.find(x => (x.getModelId()) === (lflt.getModelId()));
			if (!!connectivity) {
				connectivity.linkFromId = newLink.getModelId();
				connectivity.linkFrom = newLink;
			}
		});
	}

	/**
	 * Used for undo/redo of join/break link to reassign the original connectivity
	 * @param lookup
	 * @param linkEntity 
	 * @param linkTos from undo/redo
	 */
	public static undoRedoEndWaypointConnectivity = async(lookup: MapStore, linkEntity: LinkEntity, linkTos: LinkFromLinkTo[]) => {
		// TODO: May not reach here
		const affectedLinks: LinkEntity[] = [linkEntity];
		const mapParams = lookup.getMapParameters();
		linkTos.forEach(lt => {
			if (lt.linkFromId !== linkEntity.id) {
				console.log('should never reach here');
				lt.linkFromId = linkEntity.id;
			}
			const { linkToId } = lt;
			const linkTo = lookup.getEntity(linkToId, LinkEntity);
			if (!!linkTo) {
				travelPathForward(linkTo, affectedLinks, getCalDZDistance(mapParams));
				// ensure that the corresponding linkTo has the connectivity
				const linkFroms = linkTo.previousLinks();
				if (!linkFroms?.find(x => x.id === linkEntity.id)) {
					linkTo.linkFroms.push(lt);
				}
			} else {
				// should never reach here 
				console.log('linkTo not found');
			}
			linkTo?.linkFroms.push(lt);
		});
		// if (linkTos.length > 0) {
		// 	linkEntity.linkTos = linkTos;
		// 	console.log(`PropertyUpdateAction: recalc driving zones`);
		// 	await ClothoidToolHelper.calculateDrivingZone(lookup, lookup.getController().getImportVersion().id, undefined, linkTos);
		// } else {
		console.log(`PropertyUpdateAction: recalc driving zones`);
		await PathToolHelper.calculateDrivingZone(lookup, lookup.getImportVersion().id, affectedLinks, undefined, 'LCEH.undoRedoEndWaypointConnectivity');
		// }
	}
}