import * as uuid from 'uuid';
import MapRenderer from '../MapRenderer';
import * as PIXI from 'pixi.js';
import { LatLng, Point, Tooltip } from 'leaflet';
import { getLeafletLatLng, PixiCoordinates, pixiCoordinates } from '../Helpers/Coordinates';
import { BayEntity } from 'Models/Entities';
import { IMenuShownStatus } from 'Views/MapComponents/EditMap';

const ZINDEX_TOP = 100000;

/**
 * All possible types of mapobjects that will be rendered in Pixi.
 */
export type MapObjectType = 'node'
	| 'path'
	| 'link'
	| 'driving_zone'
	| 'sublink'
	| 'area'
	| 'driving_area'
	| 'bay'
	| 'location'
	| 'beacon'
	| 'segment'
	| 'signal'
	| 'speed'
	| 'ruler'
	| 'ahs_area';

interface TooltipHandler {
	tooltip?: Tooltip;
	content: string;
	position: LatLng;
}

/**
 * Extension class for all pixi map objects. It provides methods to
 * - Add and remove map object from the appropriate container
 * - Expose object variables
 * - Create pixi graphics and push it to containers
 * - Create pixi sprite and push it to containers
 */
export default abstract class MapObject<T> {
	protected renderer: MapRenderer;
	protected id: string;
	protected mapType: MapObjectType;

	// Whether or not an error/warning exists on this map object (used to render error/warning styling)
	private _isError: boolean = false;
	private _isWarning: boolean = false;

	// When there is division by sub-layer, zIndexBase will
	// determines the zIndex of the sublayer type within the container
	protected zIndexBase = 0;
	// zIndexTop must be greater than the highest zIndexBase so that
	// an object can be shown at the top of its layer.
	protected zIndexTop = 1;

	protected containers: PIXI.Container[] = [];

	// Used to store a reference to the leaflet tooltip, for later removal
	protected tooltipHandler: TooltipHandler | undefined;

	protected entity: T;

	protected parent: MapObject<any> | undefined;
	protected children: MapObject<any>[] | undefined;

	protected highlighted = false;

	private _lastUpdate: number = 0;

	protected constructor(renderer: MapRenderer, mapType: MapObjectType, entity: T) {
		this.renderer = renderer;
		this.id = uuid.v4();
		this.mapType = mapType;

		this.entity = entity;

		this._lastUpdate = Date.now();
	}

	/**
	 * Default implementation of render is to render any children
	 */
	public render(): void {
		if (this.children !== undefined) {
			this.children.forEach(child => child.render());
		}
	}

	public set isError(isError: boolean) {
		this._isError = isError;
	}

	public get isError() {
		return this._isError;
	}

	public set isWarning(isWarning: boolean) {
		this._isWarning = isWarning;
	}

	public get isWarning() {
		return this._isWarning;
	}

	public addToContainer(): void {
		const container = this.renderer.getContainer(this.getType());
		this.containers.forEach(childContainer => container.addChild(childContainer));

		// Add any children
		if (this.children !== undefined) {
			this.children.forEach(child => child.addToContainer());
		}
	}

	public removeFromContainer() {
		const container = this.renderer.getContainer(this.getType());
		this.containers.forEach(childContainer => container.removeChild(childContainer));

		if (this.tooltipHandler) {
			this.removeTooltip();
		}

		this.dispose();

		// Remove any children from the container
		if (this.children !== undefined) {
			this.children.forEach(child => child.removeFromContainer());
		}
	}

	public generateHitAreaFromLine(points: PixiCoordinates[] | PIXI.Point[], hitAreaThickness: number) {
		const leftPoints: PIXI.IPointData[] = [];
		const rightPoints: PIXI.IPointData[] = [];

		let headingRadians = 0;
		for (let i = 0; i < points.length; i++) {
			const p1 = points[i];
			if (i < points.length - 1) {
				const p2 = points[i + 1];
				headingRadians = Math.atan2(p2.y - p1.y, p2.x - p1.x);
			}

			const leftX = p1.x + hitAreaThickness * Math.cos(headingRadians + Math.PI / 2);
			const leftY = p1.y + hitAreaThickness * Math.sin(headingRadians + Math.PI / 2);
			const rightX = p1.x + hitAreaThickness * Math.cos(headingRadians - Math.PI / 2);
			const rightY = p1.y + hitAreaThickness * Math.sin(headingRadians - Math.PI / 2);

			leftPoints.push(new PIXI.Point(leftX, leftY));
			rightPoints.push(new PIXI.Point(rightX, rightY));
		}

		return leftPoints.concat(rightPoints.reverse());
	}

	public getCentreOfPoints(points: PIXI.Point[], renderer: MapRenderer): LatLng {
		// Calculate the min and max points for both x and y in a single loop
		const [minX, maxX, minY, maxY] = points
			.reduce(([_minX, _maxX, _minY, _maxY]: number[], { x, y }) => ([
				x < _minX ? x : _minX,
				x > _maxX ? x : _maxX,
				y < _minY ? y : _minY,
				y > _maxY ? y : _maxY,
			]),
			[
				Number.POSITIVE_INFINITY,
				Number.NEGATIVE_INFINITY,
				Number.POSITIVE_INFINITY,
				Number.NEGATIVE_INFINITY,
			]);

		return getLeafletLatLng(
			this.renderer.unproject(pixiCoordinates((minX + maxX) / 2, (minY + maxY) / 2)),
		);
	}

	/**
	 * Method that can be overriden to dispose of any graphics object that wouldn't normally be disposed of
	 * (Normal graphics objects will be disposed of correctly)
	 */
	public dispose() {

	}

	/**
	 * Gets the uuid of the mapObject
	 * @returns id 
	 */
	public getId(): string {
		return this.id;
	}

	public get isHighlighted() {
		this.highlighted = this.highlighted || this.renderer.getHighlightedMapObjectId() === this.getId();

		return this.highlighted;
	}

	public setHighlighted(isHighlighted: boolean, includeChildren: boolean = false) {
		this.highlighted = isHighlighted;
		this.renderer.markObjectToRerender(this.getId());
		if (includeChildren) {
			this.getChildren().forEach(child => {
				child.setHighlighted(isHighlighted);
				this.renderer.markObjectToRerender(child.getId());
			});
		}
	}

	/**
	 * Gets type of mapObject (node, link, etc.)
	 * @returns type 
	 */
	public getType(): MapObjectType {
		return this.mapType;
	}

	public setParent(mapObject: MapObject<any>) {
		this.parent = mapObject;
	}

	public getParent() {
		return this.parent;
	}

	public getContainers(): PIXI.Container[] {
		return this.containers;
	}	
	
	public addChild(mapObject: MapObject<any>) {
		if (!this.children) {
			this.children = [];
		}

		mapObject.setParent(this);

		this.children.push(mapObject);
	}

	public removeChild(id: string) {
		this.children = this.children?.filter(mapObject => mapObject.id !== id);
	}

	public getChildren(): MapObject<any>[] {
		return this.children ?? [];
	}

	public clearChildren() {
		this.children = undefined;
	}

	public getEntity(): T {
		return this.entity;
	}

	public setEntity(entity: T) {
		this.entity = entity;
	}

	protected createTooltip(content: string, position: LatLng, hasErrors?: boolean, isHide?: boolean) {
		// Remove the tooltip before creating the new one
		if (this.tooltipHandler) {
			this.removeTooltip();
		}

		this.tooltipHandler = {
			content,
			position,
		};
		const isShow = isHide !== true;
		this.setTooltipDisplay(isShow, hasErrors);
	}

	public setTooltipDisplay(display: boolean, hasErrors?: boolean, hasWarnings?: boolean) {
		if (!this.tooltipHandler) {
			return;
		}

		if (display) {
			if (this.tooltipHandler.tooltip) {
				this.setTooltipDisplay(false); // Hide it first
			}

			// Hide label when map object is hide
			if (!this.containers.some(x => x.visible)) {
				return;
			}

			const tooltip = new Tooltip({ permanent: true, direction: 'center' });
			if (this.getType() === 'area' || this.getType() === 'bay') {
				const _hasErrors = hasErrors ?? this.isError;
				const _hasWarnings = hasWarnings ?? this.isWarning;
				if (_hasErrors) {
					tooltip.options.className = 'icon-left icon-circle-fill error';
				} else if (_hasWarnings) {
					tooltip.options.className = 'icon-left icon-circle-fill warning';
				}
			}
			// TODO: additional class names will be used for future show/hide label tickets
			// e.g. Each show/hide category will correspond to a class
			tooltip.setContent(this.tooltipHandler.content);
			tooltip.setLatLng(this.tooltipHandler.position);

			this.tooltipHandler.tooltip = tooltip;

			this.renderer.getMap().openTooltip(tooltip);
			if (!this.renderer.getController().getMapLookup().getLabelViewStatus().allLabels) {
				// see updateMapLabels
				// TODO: in future tickets this will be per subtype 
				const el = tooltip.getElement();
				if (!!el) {
					el.hidden = true;
				}
			}
		} else {
			// TODO: Check if closeTooltip() should be prior to removeFrom()
			this.tooltipHandler.tooltip?.removeFrom(this.renderer.getMap());
			this.tooltipHandler.tooltip?.remove();
			if (this.tooltipHandler.tooltip) {
				this.renderer.getMap().closeTooltip(this.tooltipHandler.tooltip);
				this.tooltipHandler.tooltip = undefined;
			}
		}
	}

	public removeTooltip() {
		if (!this.tooltipHandler) {
			return;
		}

		this.setTooltipDisplay(false);

		// Remove everything related to the tooltip
		this.tooltipHandler = undefined;
	}

	/**
	 * - Creates graphic
	 * - Sets graphic name to the uuid of this mapObject
	 * so it can be uniquely identified when clicked
	 * - Adds graphic to Pixi container and sets interactivity
	 * @returns graphic
	 */
	protected createGraphic(display?: boolean): PIXI.Graphics {
		const graphic = new PIXI.Graphics();
		graphic.name = this.getId();
		graphic.interactive = true;
		graphic.interactiveChildren = true;
		// graphic.eventMode = 'none';
		if(display === false) {
			graphic.visible = false;
		}

		this.containers.push(graphic);

		return graphic;
	}

	/**
	 * Create a text object that can be scaled and put on top layer
	 * @param str text to display
	 * @returns PIXI.Text object
	 */
	protected createText(str: string): PIXI.Text {
		const pixiText = new PIXI.Text(str, {fontFamily : 'Arial', fontSize: 120, fill : 0x000000, align : 'center'});
		pixiText.zIndex = ZINDEX_TOP;
		pixiText.cacheAsBitmap = false;
		pixiText.name = this.getId();
		this.containers.push(pixiText);
		return pixiText;
	}

	protected getGraphic(index?: number): PIXI.Graphics {
		if ((index ?? 0) >= this.containers.length) {
			return this.createGraphic();
		}
		return this.containers[index ?? 0] as PIXI.Graphics;
	}

	protected getText(index?: number, str?: string): PIXI.Text {
		if ((index ?? 0) >= this.containers.length) {
			return this.createText(str ?? "");
		}
		return this.containers[index ?? 0] as PIXI.Text;
	}

	protected isAnimating(info?: string) {
		const now = Date.now();
		if (now - this._lastUpdate > 30000) {
			this._lastUpdate = now;
			console.log(`requestAnimationFrame: ${this.getType()} ${info ?? ''}`);
		}
	}

	/**
	 * Creates sprite and adds to container
	 * @returns sprite 
	 */
	protected createSprite(): PIXI.Sprite {
		const sprite = new PIXI.Sprite();
		sprite.name = this.getId();

		this.containers.push(sprite);

		return sprite;
	}

	// TODO: refactor. object-specific display code should be within that object
	public displayGraphic(
		display: boolean, 
		toggleFromLayersPanel: boolean = true,
		toggleType?: string,
		isViewMenuShown?: IMenuShownStatus
	) {
		let isTogglable = true; // If the visible icon of the entity is able to affect entity display
		if(toggleFromLayersPanel && !!isViewMenuShown) {
			// TODO: check if toggleType will ever be different to this.mapType? if not, simplify logic
			// If view menu status of node/sublink is hidden,
			// toggling the visible icon doesn't affect the entity display (isTogglable is false)
			switch (toggleType) {
				case 'node':
					isTogglable = isViewMenuShown.node;
					break;
				case 'sublink':
					isTogglable = isViewMenuShown.sublink;
					break;
				default:
					isTogglable = true;
					break;
			}
		}

		if(isTogglable) {
			this.containers.forEach(p => { 
				p.visible = isTogglable && display;
			});
			this.setTooltipDisplay(display);
		}

		// Showing/hidding sublinks from menu doesn't impact its children(driving zone)
		if(!toggleFromLayersPanel && toggleType === 'sublink') return;

		if (this.children && this.children.length !== 0) {
			this.children.forEach(child => {
				// (if from link edit mode, should) Prevent pathObject show up all children
				if (child.mapType === 'signal') return;
				child.displayGraphic(display)
			});
		}
	}

	public markToReRender() {
		this.renderer.markObjectToRerender(this.getId());
	}

	public setRenderable(renderable: boolean) {
		this.containers.forEach(p => { p.renderable = renderable; });

		this.setTooltipDisplay(renderable);

		if (this.children && this.children.length !== 0) {
			this.children.forEach(child => child.setRenderable(renderable));
		}
	}

	public isRenderable() {
		return this.containers.every(p => p.renderable);
	}

	public panToObject() { }

	protected static getCentreOfPoints(points: PixiCoordinates[], renderer: MapRenderer): LatLng {
		// Calculate the min and max points for both x and y in a single loop
		const [minX, maxX, minY, maxY] = points
			.reduce(([_minX, _maxX, _minY, _maxY]: number[], { x, y }: PixiCoordinates) => ([
				x < _minX ? x : _minX,
				x > _maxX ? x : _maxX,
				y < _minY ? y : _minY,
				y > _maxY ? y : _maxY,
			]),
			[
				Number.POSITIVE_INFINITY,
				Number.NEGATIVE_INFINITY,
				Number.POSITIVE_INFINITY,
				Number.NEGATIVE_INFINITY,
			]);

		return getLeafletLatLng(
			renderer.unproject(pixiCoordinates((minX + maxX) / 2, (minY + maxY) / 2)),
		);
	}

	public resetScale() {
		this._updateScale(true);
	}

	public updateScale() {
		this._updateScale(false);
	}

	public _updateScale(isReset: boolean) {

	}
}
