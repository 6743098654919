import React, { useState } from 'react';
import { observable, runInAction } from 'mobx';
import { turnSignal, turnSignalOptions } from 'Models/Enums';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import { NumberTextField } from '../../Components/NumberTextBox/NumberTextBox';
import alertToast from '../../../Util/ToastifyUtils';
import {LinkEntity, SignalSetEntity} from '../../../Models/Entities';
import { RenderInformationCombobox } from "./PropertiesPanelComponents/RenderInformationCombobox";
import { handleOnEnter, truncateNumber } from './PropertiesSidePanel';
import TurnSignalHelper from '../Map/MapStateHandlerHelpers/TurnSignalHelper';
import LinkOperationsHelper, {
	updateLinkStateOnly,
	updateLinkSublinkStatesOnly
} from '../Map/MapStateHandlerHelpers/LinkOperationsHelper';
import CreateTurnSignalCommand from "../ChangeTracker/ChangeTypes/CreateTurnSignalCommand";
import UpdateTurnSignalCommand from "../ChangeTracker/ChangeTypes/UpdateTurnSignalCommand";
import DeleteTurnSignalCommand from "../ChangeTracker/ChangeTypes/DeleteTurnSignalCommand";
import classNames from 'classnames';
import {upperCaseFirst} from "../../../Util/StringUtils";
import InputField from "./PropertiesPanelComponents/InputField";
import MapController from "../Map/MapController";
import {DerivedProperties} from "./LinkProperties";

const SIGNAL_NOT_AN_INTEGER = 'The Start position and Length should be an integer.';
const SIGNAL_START_NEGATIVE = 'The Start position should not be negative.';
const SIGNAL_START_INVALID = 'The Start position cannot be greater than 99m or (total length of the link - 1m). The minimum turn signal length is 1m.';
const SIGNAL_LENGTH_EMPTY = 'The Length should not be empty or 0.';
const SIGNAL_LENGTH_LESS_THAN_ONE = 'The Length should not be negative and the minimum allowed value is 1m.';
const SIGNAL_LENGTH_OVER_LINK_100 = 'The turn signal has been over the link or the 100m position. Set length to a valid value.';

interface TurnSignalProperties {
	type: turnSignal,
	startPosition: number,
	turnSignalLength: number | undefined,
}

interface AddTurnSignalProps {
	parentLink: LinkEntity;
	hidePanel: () => void;
	validLinkLengthForSignal: number;
	link: LinkEntity;
	map: MapController;
	setTurnSignal: React.Dispatch<React.SetStateAction<SignalSetEntity | undefined>>;
}

const AddTurnSignal: React.FC<AddTurnSignalProps> = ({ parentLink, hidePanel, validLinkLengthForSignal, link, map, setTurnSignal }) => {
	const [options, setOptions] = useState<TurnSignalProperties>(() =>
		observable({
			type: 'LEFT',
			startPosition: 0,
			turnSignalLength: undefined,
		})
	);

	const [signalStartError, setSignalStartError] = useState('');
	const [signalLengthError, setSignalLengthError] = useState('');

	const linkLength = parentLink.getDistance();

	const turnSignalOptionsCombobox = Object
		.entries(turnSignalOptions)
		.map(([value, display]) => ({ display, value }));

	const handleValidateTurnSignal = (validLinkLengthForSignal: number) => {
		setSignalStartError('');
		setSignalLengthError('');

		let error = validateTurnSignal(linkLength, options, validLinkLengthForSignal);

		if ((error === SIGNAL_NOT_AN_INTEGER && !Number.isInteger(options.startPosition)) ||
			error === SIGNAL_START_NEGATIVE || error === SIGNAL_START_INVALID) {
			alertToast(error, 'error');
			setSignalStartError('error');
			return false;
		}

		if (error === SIGNAL_NOT_AN_INTEGER || error === SIGNAL_LENGTH_EMPTY ||
			error === SIGNAL_LENGTH_LESS_THAN_ONE || error === SIGNAL_LENGTH_OVER_LINK_100) {
			if (error === SIGNAL_LENGTH_OVER_LINK_100) {
				runInAction(() => {
					setOptions(prevState => {
						return { ...prevState, turnSignalLength: Math.trunc(validLinkLengthForSignal - options.startPosition) };
					});
				});
			}
			alertToast(error, 'error');
			setSignalLengthError('error');
			return false;
		}
		return true;
	}
	
	/**
	 * Creates a new turn signal
	 */
	function createNewTurnSignal(options: TurnSignalProperties) {
		const newSignalEntity = new SignalSetEntity({
			signalType: options.type,
			signalStart: options.startPosition,
			signalEnd: options.startPosition + (options.turnSignalLength ?? 0),
			linkId: link.id,
		});
		
		newSignalEntity.id = newSignalEntity._clientId;
		const signalMapObject = TurnSignalHelper.createSignalMapObject(newSignalEntity, map, true);

		if(!!signalMapObject) {
			const renderer = map.getMapRenderer();
			const lookup = map.getMapLookup();

			renderer.addObject(signalMapObject, true);
			renderer.rerender();

			lookup.createEntity(newSignalEntity);
			const createdSignalEntity = lookup.getEntity(newSignalEntity.id, SignalSetEntity);
			lookup.setSignalSetByLinkId(createdSignalEntity as SignalSetEntity);
			map.getEventHandler().emit('onUpdateSignal', signalMapObject);

			TurnSignalHelper.addSignalToLinkEntity(newSignalEntity, link);

			// Update link state
			const oldLink = LinkOperationsHelper.copyLink(link);
			link.signalCount = 1;
			setTurnSignal(link.signalSetss[0]);

			map.getTracker().addChange(new CreateTurnSignalCommand(link.id, newSignalEntity));
		}
	}
	
	const handleAdd = (validLinkLengthForSignal: number) => {
		if (!handleValidateTurnSignal(validLinkLengthForSignal)) {
			return;
		}
		runInAction(() => {
			setOptions(prevState => {
				return { ...prevState, turnSignalLength: Math.trunc(validLinkLengthForSignal - options.startPosition) };
			});
		});
		createNewTurnSignal(options);
		hidePanel();
	};

	return (
		<div className="turn-signal-sidebar-popout">
			<div className="additional-properties-input">
				<div className="section-header">
					<h6>Add Turn Signal</h6>
					<Button
						className="close-button no-background"
						icon={{icon: 'cross', iconPos: 'icon-bottom'}}
						colors={Colors.White}
						display={Display.Text}
						onClick={() => {
							setSignalStartError('');
							setSignalLengthError('');
							hidePanel();
						}}
					/>
				</div>
				
				<div className="section-divider"/>
				<RenderInformationCombobox
					model={options}
					modelProperty="type"
					label="Signal Type"
					options={turnSignalOptionsCombobox}
					onAfterChange={() => {
						runInAction(() => {
							setOptions(prevState => {
								return { ...prevState, type: options.type };
							});
						});
					}}
					inputProps={{
						onKeyDown: handleOnEnter(() => handleAdd())
					}}
				/>

				<div className="flex">
					<NumberTextField
						className={signalStartError === 'error' ? 'add-turn-signal-error' : 'add-turn-signal'}
						model={options}
						modelProperty="startPosition"
						label="Start Position"
						inputProps={{
							maxLength: 3,
							onKeyDown: handleOnEnter(() => handleAdd())
						}}
					/>
					<span className="unit-add-turn-signal">m</span>
				</div>

				<div className="flex">
					<NumberTextField
						className={signalLengthError === 'error' ? 'add-turn-signal-error' : 'add-turn-signal'}
						model={options}
						modelProperty="turnSignalLength"
						label="Length"
						inputProps={{
							maxLength: 3,
							onKeyDown: handleOnEnter(() => handleAdd())
						}}
					/>
					<span className="unit-add-turn-signal">m</span>
				</div>

				<Button type="submit" className="turn-signal-submit btn--primary" onClick={handleAdd}>
					Add
				</Button>
			</div>
		</div>
	);
}

interface RenderTurnSignalTableProps {
	validLinkLengthForSignal: number;
	turnSignal?: SignalSetEntity;
	setTurnSignal: React.Dispatch<React.SetStateAction<SignalSetEntity | undefined>>;
	link: LinkEntity;
	map: MapController;
	derivedProperties: DerivedProperties;
	setDerivedProperties: React.Dispatch<React.SetStateAction<DerivedProperties>>;
}

/**
 * Render turn signal table
 * @constructor
 */
const RenderTurnSignalTable: React.FC<RenderTurnSignalTableProps> = ({ validLinkLengthForSignal, turnSignal,
									 setTurnSignal, link, map, 
									 derivedProperties, setDerivedProperties }) => {
	
	const handleUpdateTurnSignal = (parentLink: LinkEntity, signalType: turnSignal, startPosition: number, turnSignalLength: number | undefined) => {
		if (!turnSignal) {
			return;
		}
		
		const myOldTurnSignal = new SignalSetEntity(turnSignal);

		// Update link state
		updateLinkStateOnly(link, map.getEventHandler());

		runInAction(() => {
			turnSignal!.signalStart = startPosition;
			if (!!turnSignalLength) {
				turnSignal!.signalEnd = startPosition + turnSignalLength;
			}
		});
		TurnSignalHelper.regenerateSignalMapObject(turnSignal, map, true);
		setDerivedProperties(prevState => {
			return { ...prevState, turnSignalLength: turnSignalLength };
		});

		map.getTracker().addChange(new UpdateTurnSignalCommand(parentLink.id, turnSignal));
	};

	const handleDeleteTurnSignal = (turnSignal: SignalSetEntity) => {
		const oldLink = LinkOperationsHelper.copyLink(link);
		link.signalCount = 0;
		updateLinkSublinkStatesOnly(link);

		setTurnSignal(undefined);
		setDerivedProperties(prevState => {
			return { ...prevState, turnSignalLength: undefined };
		});

		map.getTracker().addChange(new DeleteTurnSignalCommand(oldLink.id, turnSignal.id));
	};

	const validateTurnSignalForInput = (parentLink: LinkEntity, signalType: turnSignal, startPosition: number, 
			turnSignalLength: number | undefined, validLinkLengthForSignal: number) => {
		const options: TurnSignalProperties = observable({
			type: signalType,
			startPosition: startPosition,
			turnSignalLength: turnSignalLength,
		});
		let errorMessage: string | undefined;

		const linkLength = parentLink.getDistance();
		errorMessage = validateTurnSignal(linkLength, options, validLinkLengthForSignal);

		return errorMessage;
	}
	
	return (
		<div className="turn-signal-table">
			<div className="properties-column">
				<div className="label">
					<p>Signal</p>
				</div>
				{!turnSignal || turnSignal.signalStart < 0 ? <div className="disabled-info-fields"> None </div>
					: (
						<div className="info-fields">
							<p>{upperCaseFirst(turnSignal.signalType.toLowerCase())}</p>
						</div>
					)}
			</div>
			<div className="properties-column">
				<div className="label">
					<p>Start</p>
				</div>
				{(!turnSignal || turnSignal.signalStart < 0) ? null
					: (
						<div className="info-fields">
							<InputField
								isNumber
								key={turnSignal.id ? `${turnSignal.id}_start` : `${turnSignal._clientId}_start`}
								model={turnSignal}
								modelProperty="signalStart"
								propertyUnit="m"
								maxLength={3}
								renderDisplayValue={value => truncateNumber(value)}
								onValidateInput={(value: any) => validateTurnSignalForInput(link,
									turnSignal.signalType as turnSignal, value, derivedProperties.turnSignalLength,
									validLinkLengthForSignal)}
								onUpdate={value => {
									handleUpdateTurnSignal(link,
										turnSignal.signalType as turnSignal, value, derivedProperties.turnSignalLength);
								}}
							/>
						</div>
					)}
			</div>
			<div className="properties-column">
				<div className="label">
					<p>Length</p>
				</div>
				{(!turnSignal || !derivedProperties.turnSignalLength || turnSignal.signalStart < 0) ? null
					: (
						<div className="info-fields">
							<InputField
								isNumber
								key={turnSignal.id ? `${turnSignal.id}_length` : `${turnSignal._clientId}_length`}
								model={derivedProperties}
								modelProperty="turnSignalLength"
								propertyUnit="m"
								maxLength={3}
								renderDisplayValue={value => truncateNumber(value)}
								onValidateInput={(value: any) =>
									validateTurnSignalForInput(link,
										turnSignal.signalType as turnSignal, turnSignal.signalStart, value)}
								alterValueBeforeConfirm={x => {
									if (x === undefined) {
										return x;
									}

									const maxLength = Math.trunc(validLinkLengthForSignal - turnSignal.signalStart);
									if (x > maxLength) {
										return maxLength;
									}

									return Math.trunc(x);
								}}
								onUpdate={value => {
									handleUpdateTurnSignal(link,
										turnSignal.signalType as turnSignal, turnSignal.signalStart, value);
								}}
							/>
						</div>
					)}
			</div>
			<div className="properties-column">
				<div className="label">
				</div>
				{(!turnSignal || turnSignal.signalStart < 0) ? null
					: (
						<div className="info-fields">
							<span
								className={classNames('icon', 'icon-only', 'icon-minus', 'button-width', 'turn-signal-icon')}
								onClick={() => { handleDeleteTurnSignal(turnSignal); }}
							/>
						</div>
					)}
			</div>
		</div>
	);
};

/**
 * Validate a turn signal
 * @param linkLength
 * @param options
 */
const validateTurnSignal = (linkLength: number, options: TurnSignalProperties, validLinkLengthForSignal: number): string | undefined => {
	let errorStatus: string | undefined = undefined;

	if (options.startPosition < 0) {
		return SIGNAL_START_NEGATIVE;
	}

	if (!Number.isInteger(options.startPosition)) {
		return SIGNAL_NOT_AN_INTEGER;
	}

	if (options.startPosition > linkLength - 1 || options.startPosition > 99) {
		return SIGNAL_START_INVALID;
	}

	if (!options.turnSignalLength) {
		return SIGNAL_LENGTH_EMPTY;
	}

	if (!Number.isInteger(options.turnSignalLength)) {
		return SIGNAL_NOT_AN_INTEGER;
	}

	if (options.turnSignalLength < 1) {
		return SIGNAL_LENGTH_LESS_THAN_ONE;
	}

	if (options.turnSignalLength > validLinkLengthForSignal - options.startPosition) {
		return SIGNAL_LENGTH_OVER_LINK_100;
	}

	return errorStatus;
};

export { AddTurnSignal, RenderTurnSignalTable };
