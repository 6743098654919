import PathManager from "./PathManager";
import {LinkEntity, NodeEntity, SublinkEntity} from "../../../../../../Models/Entities";
import {linkReferencePath} from "../../../MapStateHandlerHelpers/PathToolHelper";
import _, {omit} from "lodash";
import * as uuid from "uuid";
import CreatePathCommand from "../../../../ChangeTracker/ChangeTypes/CreatePathCommand";
import UpdatePathCommand from "../../../../ChangeTracker/ChangeTypes/UpdatePathCommand";

export default class ConfirmedPathManager extends PathManager {

	// Original link for reference. DO NOT USE THIS FOR ANYTHING
	private originalLink: LinkEntity;

	constructor(mapStateHandler: any, originalLink: LinkEntity) {
		super(mapStateHandler);

		this.setConfirmedPath(originalLink);
		this.validationHelper.setAvailableIds(originalLink);
	}

	public setConfirmedPath(originalLink: LinkEntity) {
		this.isEditMode = true;
		this.originalLink = originalLink;

		this.link = this.processLink(originalLink);

		this.link.sublinkss.forEach(x => {
			x.nodess.forEach(y => {
				if (y.isStartNodeOfLink()
					|| y.isEndNodeOfLink()
					|| y.isSpecialTask()
					|| y.isMidWaypoint === true
				) {
					this.waypoints.addWaypoint(y.getCoordinates(), {
						id: y.getModelId(),
						isConfirmed: true,
						task: y.task,
						direction: y.getDirection(),
						heading: y.getHeading(),
					});
				}

				if (!y.isStartNodeOfLink() && !y.isEndNodeOfLink()) {
					y.isMidWaypoint = y.isMidWaypoint || y.task === 'REVERSEPOINT';
				}
			});
		});

		this.link.linkFroms?.forEach(x => {
			this.waypoints.addPreviousConnection(x.linkFromId);
		});

		this.link.linkTos?.forEach(x => {
			this.waypoints.addNextConnection(x.linkToId);
		});

		this.renderHelper.setConfirmingPath(false, this.link);
		this.propertiesHelper.setTaskProperty(this.waypoints.lastWaypoint.task);
	}

	public confirmPath(): boolean {
		if (this.isProcessing()) return false;

		// Check there are no errors
		if (!this.mapEventHandler.getController().isActionConfirmAllowed()) {
			return false;
		}

		if (!!this.link && this.originalLink) {
			// Assign ids to the link and add it to the map store
			this.mergeLink(this.link, this.originalLink);
			this.validationHelper.assignIdsForLink(this.link);

			// Add the connections if they exist
			this.link.addPreviousLinks(this.waypoints.getPreviousConnections());
			this.link.addNextLinks(this.waypoints.getNextConnections());

			this.renderHelper.setConfirmingPath(true, this.link);

			this.mapTracker.addChange(new UpdatePathCommand(this.link));

			this.isConfirmed = true;

			return true;
		}

		return false;
	}

	private processLink(link: LinkEntity) {
		const newLink = _.cloneDeep(link);

		newLink.sortLink();

		// Update all the ids of the link, sublinks and nodes
		newLink.id = uuid.v4();
		newLink.sublinkss.forEach(x => {
			x.id = uuid.v4();

			x.nodess.forEach(y => {
				y.id = uuid.v4();
			});
		});

		// Update link references and ids
		newLink.sublinkss.forEach((s, i, sublinks) => {
			// Update values on the sublink to make processing easier later
			s.link = newLink;
			s.linkId = newLink.getModelId();

			const previousSublink = i !== 0 ? sublinks[i - 1] : undefined;
			s.previousSublinkId = previousSublink?.getModelId();
			s.previousSublink = previousSublink as any; // Ignore type checking here

			s.nextSublink = i !== sublinks.length - 1 ? sublinks[i + 1] : undefined;

			s.nodess.forEach((n, j, nodes) => {
				n.sublink = s;
				n.sublinkId = s.getModelId();

				if (j !== 0) {
					n.previousNode = s.nodess[j - 1];
					n.previousNodeId = s.nodess[j - 1]?.getModelId();
				}

				// Add the next node
				if (j !== nodes.length - 1) {
					n.nextNode = s.nodess[j + 1];
				}

				n.heading = n.getHeading();
			})
		});

		return newLink;
	}

	/**
	 * Merge two links together in preparation for the confirmed path
	 *
	 * @param link the link we want to merge into
	 * @param originalLink the link we want to pull the attributes from
	 */
	private mergeLink(link: LinkEntity, originalLink: LinkEntity) {
		link.assignAttributes(omit(originalLink, 'id', 'sublinkss', 'linkFroms', 'linkTos'));

		const linkIsDefaultSpeed = link.isDefaultSpeed;
		const constantSpeed = link.constantSpeed;
		const speedForward = this.mapStore.getMapParameters().forwardSpeed;
		const speedBackward = -this.mapStore.getMapParameters().backwardSpeed;

		let currentSpeed = speedForward;

		// Assign the isImported flag to the sublinks and nodes that are given an id from the original link
		const isImported = link.isImported;

		const waypoints = this.waypoints;

		// reassign the original link id to the new link
		link.id = originalLink.id;

		link.sublinkss.forEach((sublink, i, sublinks) => {
			sublink.isImported = isImported;
			sublink.linkId = link.id;

			sublink.nodess.forEach((node, j, nodess) => {
				node.sublinkId = sublink.id;

				if (linkIsDefaultSpeed) {
					const waypoint = waypoints.getWaypoint(node.id);
					if (!!waypoint) {
						currentSpeed = waypoint.direction === 'forward' ? speedForward : speedBackward;
					}

					node.speed = node.isSpecialTask() ? 0 : currentSpeed;
				} else {
					node.speed = node.isSpecialTask() ? 0 : constantSpeed;
				}
			});
		});
	}
}